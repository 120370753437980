import React, { useState, useEffect, useRef } from 'react';
import { ref, query, orderByChild, get, update, push } from 'firebase/database';
import { auth } from '../firebase';
import { db } from '../firebase';
import { Link, useNavigate } from 'react-router-dom';
import { 
  Edit, 
  Trash2, 
  ChevronLeft, 
  ChevronRight, 
  Plus, 
  AlertCircle, 
  Upload, 
  Search, 
  Download,
  ArrowUpDown,
  ArrowUp,
  ArrowDown
} from 'lucide-react';
import Papa from 'papaparse';
import Select from 'react-select';

const ProductList = () => {
  // State Management
  const [products, setProducts] = useState([]);
  const [companies, setCompanies] = useState({});
  const [stores, setStores] = useState({});
  const [categories, setCategories] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [isDuplicateChecking, setIsDuplicateChecking] = useState(false);
  const fileInputRef = useRef(null);

  // Filter and Sort States
  const [searchTerm, setSearchTerm] = useState('');
  const [filters, setFilters] = useState({
    company: '',
    store: '',
    category: ''
  });
  const [sortConfig, setSortConfig] = useState({
    key: 'dateModified',
    direction: 'desc'
  });

  // Constants
  const PAGE_SIZE = 50;
  const navigate = useNavigate();

  // Sort options
  const sortOptions = [
    { value: 'dateModified-desc', label: 'Most Recent' },
    { value: 'dateModified-asc', label: 'Oldest First' },
    { value: 'productName-asc', label: 'Name (A-Z)' },
    { value: 'productName-desc', label: 'Name (Z-A)' },
    { value: 'productNumber-asc', label: 'Product Number (Asc)' },
    { value: 'productNumber-desc', label: 'Product Number (Desc)' },
    { value: 'competitorProductName-asc', label: 'Competitor Product (A-Z)' },
    { value: 'competitorProductName-desc', label: 'Competitor Product (Z-A)' }
  ];

  // Sample CSV data
  const sampleCsvData = [
    {
      productName: "Sample Product 1",
      productNumber: "PRD001",
      companyName: "Example Company Name",
      storeName: "Example Store Name",
      categoryName: "Example Category Name",
      competitorProductName: "Competitor A"
    },
    {
      productName: "Sample Product 2",
      productNumber: "PRD002",
      companyName: "Example Company Name",
      storeName: "Example Store Name",
      categoryName: "Example Category Name",
      competitorProductName: "Competitor B"
    }
  ];

  useEffect(() => {
    fetchInitialData();
  }, []);

  // Sorting Functions
  const handleSort = (option) => {
    const [key, direction] = option.value.split('-');
    setSortConfig({ key, direction });
    setCurrentPage(1);
  };

  const handleColumnSort = (key) => {
    setSortConfig(current => ({
      key,
      direction: current.key === key && current.direction === 'asc' ? 'desc' : 'asc'
    }));
    setCurrentPage(1);
  };

  const getSortIcon = (columnKey) => {
    if (sortConfig.key !== columnKey) {
      return <ArrowUpDown size={16} className="text-gray-400" />;
    }
    return sortConfig.direction === 'asc' 
      ? <ArrowUp size={16} className="text-blue-500" />
      : <ArrowDown size={16} className="text-blue-500" />;
  };

  const getSortedProducts = (products) => {
    if (!sortConfig.key) return products;

    return [...products].sort((a, b) => {
      let aValue = a[sortConfig.key];
      let bValue = b[sortConfig.key];

      // Handle nested values for company, store, and category names
      if (sortConfig.key === 'company') {
        aValue = companies[a.companyId]?.companyName || '';
        bValue = companies[b.companyId]?.companyName || '';
      } else if (sortConfig.key === 'store') {
        aValue = stores[a.storeId]?.storeName || '';
        bValue = stores[b.storeId]?.storeName || '';
      } else if (sortConfig.key === 'category') {
        aValue = categories[a.categoryId]?.categoryName || '';
        bValue = categories[b.categoryId]?.categoryName || '';
      }

      // Case insensitive comparison for strings
      if (typeof aValue === 'string') {
        aValue = aValue.toLowerCase();
        bValue = bValue.toLowerCase();
      }

      if (aValue < bValue) {
        return sortConfig.direction === 'asc' ? -1 : 1;
      }
      if (aValue > bValue) {
        return sortConfig.direction === 'asc' ? 1 : -1;
      }
      return 0;
    });
  };

  // Selection Handlers
  const handleSelectAll = (e) => {
    setSelectAll(e.target.checked);
    if (e.target.checked) {
      setSelectedItems(getCurrentPageProducts().map(item => item.id));
    } else {
      setSelectedItems([]);
    }
  };

  const handleSelectItem = (productId) => {
    setSelectedItems(prevSelected => {
      if (prevSelected.includes(productId)) {
        return prevSelected.filter(id => id !== productId);
      } else {
        return [...prevSelected, productId];
      }
    });
  };

  // Data Fetching and Management
  const fetchInitialData = async () => {
    setIsLoading(true);
    setError('');
    try {
      const [companiesSnapshot, storesSnapshot, categoriesSnapshot, productsSnapshot] = await Promise.all([
        get(ref(db, 'companies')),
        get(ref(db, 'stores')),
        get(ref(db, 'categories')),
        get(query(ref(db, 'products'), orderByChild('dateCreated')))
      ]);

      setCompanies(companiesSnapshot.val() || {});
      setStores(storesSnapshot.val() || {});
      setCategories(categoriesSnapshot.val() || {});

      if (productsSnapshot.exists()) {
        const productsData = [];
        productsSnapshot.forEach((childSnapshot) => {
          const productData = childSnapshot.val();
          if (!productData.isDeleted) {
            productsData.push({ id: childSnapshot.key, ...productData });
          }
        });
        setProducts(productsData);
      } else {
        setProducts([]);
      }
    } catch (err) {
      console.error('Error fetching data:', err);
      setError('Failed to load data. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  // Function to find duplicate products
  const findDuplicateProducts = (products) => {
    const productGroups = products.reduce((acc, product) => {
      // Create a unique key combining relevant fields (case-insensitive)
      const key = [
        product.productName.toLowerCase().trim(),
        product.productNumber.toLowerCase().trim(),
        product.companyId,
        product.storeId,
        product.categoryId
      ].join('|');
      
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(product);
      return acc;
    }, {});
    // Filter out groups with more than one product (duplicates)
    return Object.values(productGroups)
      .filter(group => group.length > 1)
      .flat();
  };

  // Function to handle finding and removing duplicates
  const handleFindDuplicates = async () => {
    setIsDuplicateChecking(true);
    setError('');
    try {
      const duplicates = findDuplicateProducts(products);
      if (duplicates.length === 0) {
        alert('No duplicates found!');
        return;
      }
      if (window.confirm(`Found ${duplicates.length} duplicate products. Would you like to remove them?`)) {
        const productMap = new Map();
        const updates = {};
        // Keep the most recently modified version of each product
        duplicates.forEach(product => {
          const key = [
            product.productName.toLowerCase().trim(),
            product.productNumber.toLowerCase().trim(),
            product.companyId,
            product.storeId,
            product.categoryId
          ].join('|');
          if (!productMap.has(key)) {
            productMap.set(key, product);
          } else {
            const existing = productMap.get(key);
            const productToKeep = new Date(existing.dateModified) > new Date(product.dateModified) 
              ? existing 
              : product;
            const productToDelete = productToKeep === existing ? product : existing;
            
            productMap.set(key, productToKeep);
            updates[`products/${productToDelete.id}`] = {
              ...productToDelete,
              isDeleted: true,
              dateModified: new Date().toISOString()
            };
          }
        });
        await update(ref(db, 'products'), updates);
        await fetchInitialData();
        alert(`Successfully removed ${Object.keys(updates).length} duplicate products.`);
      }
    } catch (err) {
      console.error('Error handling duplicates:', err);
      setError('Failed to process duplicates. Please try again.');
    } finally {
      setIsDuplicateChecking(false);
    }
  };

  const findIdByName = (collection, field, name) => {
    return Object.entries(collection).find(([_, item]) => 
      item[field]?.toLowerCase() === name?.toLowerCase()
    )?.[0];
  };

  // Bulk Operations
  const handleBulkDelete = async () => {
    if (selectedItems.length === 0) {
      alert('Please select items to delete');
      return;
    }

    if (window.confirm(`Are you sure you want to delete ${selectedItems.length} products?`)) {
      setIsDeleting(true);
      try {
        const updates = {};
        selectedItems.forEach(productId => {
          updates[`products/${productId}`] = {
            ...products.find(prod => prod.id === productId),
            isDeleted: true,
            dateModified: new Date().toISOString()
          };
        });

        await update(ref(db), updates);
        await fetchInitialData();
        setSelectedItems([]);
        setSelectAll(false);
        alert('Products deleted successfully');
      } catch (err) {
        console.error('Error deleting products:', err);
        setError('Failed to delete products. Please try again.');
      } finally {
        setIsDeleting(false);
      }
    }
  };

  // Single Item Operations
  const handleDeleteProduct = async (productId) => {
    if (window.confirm('Are you sure you want to delete this product?')) {
      setIsDeleting(true);
      try {
        const productRef = ref(db, `products/${productId}`);
        await update(productRef, { 
          isDeleted: true, 
          dateModified: new Date().toISOString() 
        });
        await fetchInitialData();
        alert('Product deleted successfully');
      } catch (err) {
        console.error('Error deleting product:', err);
        setError('Failed to delete product. Please try again.');
      } finally {
        setIsDeleting(false);
      }
    }
  };

  // CSV Operations
  const downloadSampleCsv = () => {
    const headerRow = {
      productName: "Product Name (Required)",
      productNumber: "Product Number (Required)",
      companyName: "Company Name (Required - Must match existing company)",
      storeName: "Store Name (Required - Must match existing store)",
      categoryName: "Category Name (Required - Must match existing category)",
      competitorProductName: "Competitor Product Name (Optional)"
    };

    const csvData = [headerRow, ...sampleCsvData];
    const csv = Papa.unparse(csvData);
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', 'product_template.csv');
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      setIsUploading(true);
      setError('');
      Papa.parse(file, {
        complete: handleFileParseComplete,
        header: true,
        error: (error) => {
          setError(`Error parsing file: ${error.message}`);
          setIsUploading(false);
        }
      });
    }
  };

  const handleFileParseComplete = async (results) => {
    try {
      const { data } = results;
      const productsRef = ref(db, 'products');
      const updates = {};
      const errors = [];
      let rowNum = 0;
      const newProducts = [];
  
      if (!auth.currentUser) {
        throw new Error('You must be logged in to upload products.');
      }
  
      for (const row of data) {
        rowNum++;
        if (!row.productName || !row.productNumber || !row.companyName || !row.storeName || !row.categoryName) {
          errors.push(`Row ${rowNum}: Missing required fields`);
          continue;
        }
  
        const companyId = findIdByName(companies, 'companyName', row.companyName);
        const storeId = findIdByName(stores, 'storeName', row.storeName);
        const categoryId = findIdByName(categories, 'categoryName', row.categoryName);
  
        if (!companyId) {
          errors.push(`Row ${rowNum}: Company "${row.companyName}" not found`);
          continue;
        }
        if (!storeId) {
          errors.push(`Row ${rowNum}: Store "${row.storeName}" not found`);
          continue;
        }
        if (!categoryId) {
          errors.push(`Row ${rowNum}: Category "${row.categoryName}" not found`);
          continue;
        }
  
        const newProduct = {
          productName: row.productName.trim(),
          productNumber: row.productNumber.trim(),
          companyId,
          storeId,
          categoryId,
          competitorProductName: row.competitorProductName?.trim() || '',
          dateCreated: new Date().toISOString(),
          dateModified: new Date().toISOString(),
          createdBy: auth.currentUser.uid
        };
  
        // Check for duplicates in existing products and new data
        const isDuplicate = [...products, ...newProducts].some(product => 
          product.productName.toLowerCase().trim() === newProduct.productName.toLowerCase().trim() &&
          product.productNumber.toLowerCase().trim() === newProduct.productNumber.toLowerCase().trim() &&
          product.companyId === newProduct.companyId &&
          product.storeId === newProduct.storeId &&
          product.categoryId === newProduct.categoryId
        );
  
        if (isDuplicate) {
          errors.push(`Row ${rowNum}: Duplicate product entry detected`);
          continue;
        }
  
        newProducts.push(newProduct);
      }
  
      if (errors.length > 0) {
        setError(`Upload errors:\n${errors.join('\n')}`);
        return;
      }
  
      if (newProducts.length === 0) {
        setError('No valid products found in the CSV file');
        return;
      }
  
      // Add new products to updates object
      newProducts.forEach(product => {
        const newProductRef = push(productsRef);
        updates[newProductRef.key] = product;
      });
  
      // Update the database with the correct reference
      await update(productsRef, updates);
      alert(`Successfully uploaded ${Object.keys(updates).length} products`);
      await fetchInitialData();
    } catch (error) {
      console.error('Error uploading products:', error);
      setError('Failed to upload products. Please try again.');
    } finally {
      setIsUploading(false);
    }
  };

  // Filtering and Pagination
  const getFilteredProducts = () => {
    const filtered = products.filter(product => {
      const matchesSearch = !searchTerm || 
        product.productName.toLowerCase().includes(searchTerm.toLowerCase()) ||
        product.productNumber.toLowerCase().includes(searchTerm.toLowerCase()) ||
        product.competitorProductName?.toLowerCase().includes(searchTerm.toLowerCase());
      
      const matchesCompany = !filters.company || product.companyId === filters.company;
      const matchesStore = !filters.store || product.storeId === filters.store;
      const matchesCategory = !filters.category || product.categoryId === filters.category;

      return matchesSearch && matchesCompany && matchesStore && matchesCategory;
    });

    return getSortedProducts(filtered);
  };

  const getCurrentPageProducts = () => {
    const filteredProducts = getFilteredProducts();
    const startIndex = (currentPage - 1) * PAGE_SIZE;
    return filteredProducts.slice(startIndex, startIndex + PAGE_SIZE);
  };

  const handleNextPage = () => {
    const filteredProducts = getFilteredProducts();
    if (currentPage < Math.ceil(filteredProducts.length / PAGE_SIZE)) {
      setCurrentPage(currentPage + 1);
      setSelectAll(false);
      setSelectedItems([]);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
      setSelectAll(false);
      setSelectedItems([]);
    }
  };

  return (
    <div className="container mx-auto px-4 sm:px-6 lg:px-8 py-8 text-left">
      {/* Header Section */}
      <div className="flex flex-col sm:flex-row justify-between items-center mb-6">
        <h2 className="text-2xl font-semibold leading-tight mb-2 sm:mb-0">Product List</h2>
        <div className="flex flex-wrap gap-2">
          <button
            onClick={downloadSampleCsv}
            className="bg-purple-500 hover:bg-purple-700 text-white font-bold py-2 px-4 rounded flex items-center"
          >
            <Download className="mr-2" size={20} />
            Download Template
          </button>
          <button
            onClick={handleFindDuplicates}
            className="bg-yellow-500 hover:bg-yellow-700 text-white font-bold py-2 px-4 rounded flex items-center"
            disabled={isDuplicateChecking}
          >
            <AlertCircle className="mr-2" size={20} />
            {isDuplicateChecking ? 'Checking...' : 'Find Duplicates'}
          </button>
          <button
            onClick={() => fileInputRef.current.click()}
            className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded flex items-center"
            disabled={isUploading}
          >
            <Upload className="mr-2" size={20} />
            {isUploading ? 'Uploading...' : 'Upload CSV'}
          </button>
          <input
            type="file"
            ref={fileInputRef}
            onChange={handleFileUpload}
            accept=".csv"
            className="hidden"
          />
          <button
            onClick={() => navigate('/add-product')}
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded flex items-center"
          >
            <Plus className="mr-2" size={20} />
            Add Product
          </button>
          {selectedItems.length > 0 && (
            <button
              onClick={handleBulkDelete}
              className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded flex items-center"
              disabled={isDeleting}
            >
              <Trash2 className="mr-2" size={20} />
              Delete ({selectedItems.length})
            </button>
          )}
        </div>
      </div>

      {/* Search, Sort, and Filters Section */}
      <div className="mb-6 grid grid-cols-1 md:grid-cols-5 gap-4">
        <div className="relative">
          <input
            type="text"
            placeholder="Search products..."
            className="w-full px-4 py-2 border rounded-lg"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <Search className="absolute right-3 top-2.5 text-gray-400" size={20} />
        </div>

        <Select
          placeholder="Sort by..."
          options={sortOptions}
          onChange={handleSort}
          value={sortOptions.find(
            option => option.value === `${sortConfig.key}-${sortConfig.direction}`
          )}
          className="w-full"
        />
        
        <Select
          placeholder="Select Company"
          options={Object.entries(companies).map(([id, company]) => ({
            value: id,
            label: company.companyName
          }))}
          value={filters.company ? { 
            value: filters.company, 
            label: companies[filters.company]?.companyName 
          } : null}
          onChange={(option) => setFilters({ ...filters, company: option ? option.value : '' })}
          isClearable
          className="w-full"
        />

        <Select
          placeholder="Select Store"
          options={Object.entries(stores).map(([id, store]) => ({
            value: id,
            label: store.storeName
          }))}
          value={filters.store ? { 
            value: filters.store, 
            label: stores[filters.store]?.storeName 
          } : null}
          onChange={(option) => setFilters({ ...filters, store: option ? option.value : '' })}
          isClearable
          className="w-full"
        />

        <Select
          placeholder="Select Category"
          options={Object.entries(categories).map(([id, category]) => ({
            value: id,
            label: category.categoryName
          }))}
          value={filters.category ? { 
            value: filters.category, 
            label: categories[filters.category]?.categoryName 
          } : null}
          onChange={(option) => setFilters({ ...filters, category: option ? option.value : '' })}
          isClearable
          className="w-full"
        />
      </div>

      {error && (
        <div className="mb-4 p-3 bg-red-100 text-red-700 rounded flex items-center">
          <AlertCircle className="mr-2" size={20} />
          <pre className="whitespace-pre-wrap">{error}</pre>
        </div>
      )}

      {isLoading ? (
        <div className="flex justify-center items-center h-64">
          <div className="animate-spin rounded-full h-32 w-32 border-b-2 border-gray-900"></div>
        </div>
      ) : (
        <>
          <div className="overflow-x-auto bg-white rounded-lg shadow overflow-y-auto relative">
            <table className="border-collapse table-auto w-full whitespace-no-wrap bg-white table-striped relative">
              <thead>
                <tr className="text-left">
                  <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-3">
                    <input
                      type="checkbox"
                      checked={selectAll}
                      onChange={handleSelectAll}
                      className="form-checkbox h-4 w-4 text-blue-600"
                    />
                  </th>
                  <th 
                    className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-3 text-gray-600 font-bold tracking-wider uppercase text-xs cursor-pointer"
                    onClick={() => handleColumnSort('productName')}
                  >
                    <div className="flex items-center">
                      Product Name {getSortIcon('productName')}
                    </div>
                  </th>
                  <th 
                    className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-3 text-gray-600 font-bold tracking-wider uppercase text-xs cursor-pointer"
                    onClick={() => handleColumnSort('productNumber')}
                  >
                    <div className="flex items-center">
                      Product Number {getSortIcon('productNumber')}
                    </div>
                  </th>
                  <th 
                    className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-3 text-gray-600 font-bold tracking-wider uppercase text-xs cursor-pointer"
                    onClick={() => handleColumnSort('company')}
                  >
                    <div className="flex items-center">
                      Company {getSortIcon('company')}
                    </div>
                  </th>
                  <th 
                    className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-3 text-gray-600 font-bold tracking-wider uppercase text-xs cursor-pointer"
                    onClick={() => handleColumnSort('store')}
                  >
                    <div className="flex items-center">
                      Store {getSortIcon('store')}
                    </div>
                  </th>
                  <th 
                    className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-3 text-gray-600 font-bold tracking-wider uppercase text-xs cursor-pointer"
                    onClick={() => handleColumnSort('category')}
                  >
                    <div className="flex items-center">
                      Category {getSortIcon('category')}
                    </div>
                  </th>
                  <th 
                    className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-3 text-gray-600 font-bold tracking-wider uppercase text-xs cursor-pointer"
                    onClick={() => handleColumnSort('competitorProductName')}
                  >
                    <div className="flex items-center">
                      Competitor Product {getSortIcon('competitorProductName')}
                    </div>
                  </th>
                  <th 
                    className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-3 text-gray-600 font-bold tracking-wider uppercase text-xs cursor-pointer"
                    onClick={() => handleColumnSort('dateModified')}
                  >
                    <div className="flex items-center">
                      Last Modified {getSortIcon('dateModified')}
                    </div>
                  </th>
                  <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-3 text-gray-600 font-bold tracking-wider uppercase text-xs">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody>
                {getCurrentPageProducts().map((product) => (
                  <tr key={product.id}>
                    <td className="border-dashed border-t border-gray-200 px-6 py-4">
                      <input
                        type="checkbox"
                        checked={selectedItems.includes(product.id)}
                        onChange={() => handleSelectItem(product.id)}
                        className="form-checkbox h-4 w-4 text-blue-600"
                      />
                    </td>
                    <td className="border-dashed border-t border-gray-200 px-6 py-4">{product.productName}</td>
                    <td className="border-dashed border-t border-gray-200 px-6 py-4">{product.productNumber}</td>
                    <td className="border-dashed border-t border-gray-200 px-6 py-4">
                      {companies[product.companyId]?.companyName || 'Unknown'}
                    </td>
                    <td className="border-dashed border-t border-gray-200 px-6 py-4">
                      {stores[product.storeId]?.storeName || 'Unknown'}
                    </td>
                    <td className="border-dashed border-t border-gray-200 px-6 py-4">
                      {categories[product.categoryId]?.categoryName || 'Unknown'}
                    </td>
                    <td className="border-dashed border-t border-gray-200 px-6 py-4">{product.competitorProductName || '-'}</td>
                    <td className="border-dashed border-t border-gray-200 px-6 py-4">
                      {new Date(product.dateModified).toLocaleString()}
                    </td>
                    <td className="border-dashed border-t border-gray-200 px-6 py-4">
                      <div className="flex space-x-3">
                        <Link 
                          to={`/edit-product/${product.id}`} 
                          className="text-indigo-600 hover:text-indigo-900"
                        >
                          <Edit size={18} />
                        </Link>
                        <button 
                          onClick={() => handleDeleteProduct(product.id)} 
                          className="text-red-600 hover:text-red-900"
                          disabled={isDeleting}
                        >
                          <Trash2 size={18} />
                        </button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          
          {/* Pagination Controls */}
          <div className="flex justify-between items-center mt-6">
            <span className="text-sm text-gray-700">
              Page {currentPage} of {Math.ceil(getFilteredProducts().length / PAGE_SIZE)} 
              {' '}({getFilteredProducts().length} total products)
              {selectedItems.length > 0 && ` • ${selectedItems.length} selected`}
            </span>
            <div className="flex space-x-2">
              <button
                onClick={handlePreviousPage}
                disabled={currentPage === 1}
                className="px-3 py-2 rounded-lg border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 disabled:opacity-50 disabled:cursor-not-allowed flex items-center"
              >
                <ChevronLeft size={20} />
                <span className="ml-1">Previous</span>
              </button>
              <button
                onClick={handleNextPage}
                disabled={currentPage >= Math.ceil(getFilteredProducts().length / PAGE_SIZE)}
                className="px-3 py-2 rounded-lg border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 disabled:opacity-50 disabled:cursor-not-allowed flex items-center"
              >
                <span className="mr-1">Next</span>
                <ChevronRight size={20} />
              </button>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default ProductList;