import React, { useState, useEffect } from 'react';
import { ref, push, set, get } from 'firebase/database';
import { db, auth } from '../firebase';
import { Plus, AlertCircle } from 'lucide-react';
import { useNavigate } from 'react-router-dom';

const AddProduct = () => {
  const [product, setProduct] = useState({
    companyId: '',
    storeId: '',
    categoryId: '',
    productName: '',
    productNumber: '',
    competitorProductName: ''
  });
  const [companies, setCompanies] = useState([]);
  const [stores, setStores] = useState([]);
  const [categories, setCategories] = useState([]);
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [storeSearchTerm, setStoreSearchTerm] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const companiesSnapshot = await get(ref(db, 'companies'));
        const companiesData = companiesSnapshot.val();
        setCompanies(Object.entries(companiesData || {}).map(([id, data]) => ({ id, ...data })));

        const storesSnapshot = await get(ref(db, 'stores'));
        const storesData = storesSnapshot.val();
        const sortedStores = Object.entries(storesData || {})
          .map(([id, data]) => ({ id, ...data }))
          .sort((a, b) => a.storeName.localeCompare(b.storeName));
        setStores(sortedStores);

        const categoriesSnapshot = await get(ref(db, 'categories'));
        const categoriesData = categoriesSnapshot.val();
        setCategories(Object.entries(categoriesData || {}).map(([id, data]) => ({ id, ...data })));
      } catch (error) {
        console.error('Error fetching data:', error);
        setError('Failed to load necessary data. Please try again.');
      }
    };

    fetchData();
  }, []);

  const filteredStores = stores.filter(store =>
    store.storeName.toLowerCase().includes(storeSearchTerm.toLowerCase())
  );

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === 'storeSearch') {
      setStoreSearchTerm(value);
      const matchingStore = stores.find(store => 
        store.storeName.toLowerCase() === value.toLowerCase()
      );
      if (matchingStore) {
        setProduct(prev => ({ ...prev, storeId: matchingStore.id }));
      }
    } else {
      setProduct({ ...product, [name]: value });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setIsLoading(true);
    try {
      if (!auth.currentUser) {
        throw new Error('You must be logged in to add a product.');
      }
      const newProductRef = push(ref(db, 'products'));
      await set(newProductRef, {
        ...product,
        dateCreated: new Date().toISOString(),
        dateModified: new Date().toISOString(),
        createdBy: auth.currentUser.uid,
      });
      console.log('Product added with ID: ', newProductRef.key);
      navigate('/products');
    } catch (error) {
      console.error('Error adding product: ', error);
      setError(error.message || 'Failed to add product. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="max-w-md mx-auto mt-10 p-6 bg-white rounded-lg shadow-xl text-left">
      <h2 className="text-2xl font-bold mb-6 text-gray-800">Add Product</h2>
      {error && (
        <div className="mb-4 p-3 bg-red-100 text-red-700 rounded flex items-center">
          <AlertCircle className="mr-2" size={20} />
          {error}
        </div>
      )}
      <form onSubmit={handleSubmit} className="space-y-4">
        <div>
          <label htmlFor="companyId" className="block text-sm font-medium text-gray-700">Company:</label>
          <select
            id="companyId"
            name="companyId"
            value={product.companyId}
            onChange={handleInputChange}
            required
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
          >
            <option value="">Select a company</option>
            {companies.map((company) => (
              <option key={company.id} value={company.id}>{company.companyName}</option>
            ))}
          </select>
        </div>
        <div>
          <label htmlFor="storeSearch" className="block text-sm font-medium text-gray-700">Store:</label>
          <input
            type="text"
            id="storeSearch"
            name="storeSearch"
            list="storesList"
            value={storeSearchTerm}
            onChange={handleInputChange}
            placeholder="Search for a store..."
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
          />
          <datalist id="storesList">
            {filteredStores.map((store) => (
              <option key={store.id} value={store.storeName} />
            ))}
          </datalist>
          <input type="hidden" name="storeId" value={product.storeId} required />
        </div>
        <div>
          <label htmlFor="categoryId" className="block text-sm font-medium text-gray-700">Category:</label>
          <select
            id="categoryId"
            name="categoryId"
            value={product.categoryId}
            onChange={handleInputChange}
            required
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
          >
            <option value="">Select a category</option>
            {categories.map((category) => (
              <option key={category.id} value={category.id}>{category.categoryName}</option>
            ))}
          </select>
        </div>
        <div>
          <label htmlFor="productName" className="block text-sm font-medium text-gray-700">Product Name:</label>
          <input
            type="text"
            id="productName"
            name="productName"
            value={product.productName}
            onChange={handleInputChange}
            required
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
          />
        </div>
        <div>
          <label htmlFor="productNumber" className="block text-sm font-medium text-gray-700">Product Number / SKU:</label>
          <input
            type="text"
            id="productNumber"
            name="productNumber"
            value={product.productNumber}
            onChange={handleInputChange}
            required
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
          />
        </div>
        <div>
          <label htmlFor="competitorProductName" className="block text-sm font-medium text-gray-700">Competitor Product Name:</label>
          <input
            type="text"
            id="competitorProductName"
            name="competitorProductName"
            value={product.competitorProductName}
            onChange={handleInputChange}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
          />
        </div>
        <button 
          type="submit" 
          className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          disabled={isLoading}
        >
          {isLoading ? 'Adding...' : (
            <>
              <Plus className="mr-2" size={20} />
              Add Product
            </>
          )}
        </button>
      </form>
    </div>
  );
};

export default AddProduct;