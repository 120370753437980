import React, { useState, useEffect, useRef } from 'react';
import { ref, query, orderByChild, limitToFirst, startAfter, get, remove, update, push } from 'firebase/database';
import { db, auth } from '../firebase';
import { Link, useNavigate } from 'react-router-dom';
import { Edit, Trash2, ChevronLeft, ChevronRight, Plus, AlertCircle, Upload } from 'lucide-react';
import Papa from 'papaparse';

const CompanyList = () => {
  const [companies, setCompanies] = useState([]);
  const [lastKey, setLastKey] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const fileInputRef = useRef(null);
  const pageSize = 10;
  const navigate = useNavigate();

  const fetchCompanies = async (key = null) => {
    setIsLoading(true);
    setError('');
    try {
      if (!auth.currentUser) {
        throw new Error('You must be logged in to view companies.');
      }
      const companiesRef = ref(db, 'companies');
      let companiesQuery = query(companiesRef, orderByChild('dateCreated'), limitToFirst(pageSize + 1));
      
      if (key) {
        companiesQuery = query(companiesQuery, startAfter(key));
      }

      const snapshot = await get(companiesQuery);
      if (snapshot.exists()) {
        const companiesData = [];
        snapshot.forEach((childSnapshot) => {
          companiesData.push({ id: childSnapshot.key, ...childSnapshot.val() });
        });
        
        const hasNextPage = companiesData.length > pageSize;
        if (hasNextPage) {
          companiesData.pop();
        }
        
        setCompanies(companiesData);
        setLastKey(hasNextPage ? companiesData[companiesData.length - 1].dateCreated : null);
      } else {
        setCompanies([]);
        setLastKey(null);
      }
    } catch (err) {
      console.error('Error fetching companies:', err);
      if (err.message.includes('Index not defined')) {
        setError('Database index not set up correctly. Please contact the administrator.');
      } else if (err.message.includes('permission_denied')) {
        setError('You do not have permission to view companies. Please check your account permissions.');
      } else {
        setError(err.message || 'Failed to fetch companies. Please try again.');
      }
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchCompanies();
  }, []);

  const handleNextPage = () => {
    if (lastKey) {
      fetchCompanies(lastKey);
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      fetchCompanies();
      setCurrentPage(currentPage - 1);
    }
  };

  const handleAddCompany = () => {
    navigate('/add-company');
  };

  const handleDeleteCompany = async (companyId) => {
    if (window.confirm('Are you sure you want to delete this company?')) {
      setIsDeleting(true);
      try {
        const companyRef = ref(db, `companies/${companyId}`);
        await remove(companyRef);
        setCompanies(companies.filter(company => company.id !== companyId));
        alert('Company deleted successfully');
      } catch (err) {
        console.error('Error deleting company:', err);
        setError('Failed to delete company. Please try again.');
      } finally {
        setIsDeleting(false);
      }
    }
  };

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      setIsUploading(true);
      setError('');
      Papa.parse(file, {
        complete: handleFileParseComplete,
        header: true,
        error: (error) => {
          setError(`Error parsing file: ${error.message}`);
          setIsUploading(false);
        }
      });
    }
  };

  const handleFileParseComplete = async (results) => {
    try {
      const { data } = results;
      const companiesRef = ref(db, 'companies');
      const updates = {};

      for (const row of data) {
        if (row.companyName && row.companyCode) {
          const newCompanyRef = push(companiesRef);
          updates[newCompanyRef.key] = {
            companyName: row.companyName,
            companyCode: row.companyCode,
            dateCreated: new Date().toISOString(),
            dateModified: new Date().toISOString(),
          };
        }
      }

      await update(companiesRef, updates);
      alert(`Successfully uploaded ${Object.keys(updates).length} companies.`);
      fetchCompanies(); // Refresh the list
    } catch (error) {
      console.error('Error uploading companies:', error);
      setError('Failed to upload companies. Please try again.');
    } finally {
      setIsUploading(false);
    }
  };

  return (
    <div className="container mx-auto px-4 sm:px-6 lg:px-8 py-8 text-left">
      <div className="flex flex-col sm:flex-row justify-between items-center mb-6">
        <h2 className="text-2xl font-semibold leading-tight mb-2 sm:mb-0">Company Info</h2>
        <div className="flex space-x-2">
          <button
            onClick={() => fileInputRef.current.click()}
            className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded flex items-center"
            disabled={isUploading}
          >
            <Upload className="mr-2" size={20} />
            {isUploading ? 'Uploading...' : 'Upload CSV'}
          </button>
          <input
            type="file"
            ref={fileInputRef}
            onChange={handleFileUpload}
            accept=".csv"
            className="hidden"
          />
          <button
            onClick={handleAddCompany}
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded flex items-center"
          >
            <Plus className="mr-2" size={20} />
            Add Company
          </button>
        </div>
      </div>
      
      {error && (
        <div className="mb-4 p-3 bg-red-100 text-red-700 rounded flex items-center">
          <AlertCircle className="mr-2" size={20} />
          {error}
        </div>
      )}
      
      {isLoading ? (
        <div className="flex justify-center items-center h-64">
          <div className="animate-spin rounded-full h-32 w-32 border-b-2 border-gray-900"></div>
        </div>
      ) : (
        <>
          {companies.length === 0 ? (
            <div className="text-center text-gray-500 mt-4">No companies found.</div>
          ) : (
            <div className="overflow-x-auto bg-white rounded-lg shadow overflow-y-auto relative">
              <table className="border-collapse table-auto w-full whitespace-no-wrap bg-white table-striped relative">
                <thead>
                  <tr className="text-left">
                    <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-3 text-gray-600 font-bold tracking-wider uppercase text-xs">Company Name</th>
                    <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-3 text-gray-600 font-bold tracking-wider uppercase text-xs">Company Code</th>
                    <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-3 text-gray-600 font-bold tracking-wider uppercase text-xs">Date Modified</th>
                    <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-3 text-gray-600 font-bold tracking-wider uppercase text-xs">Date Created</th>
                    <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-3 text-gray-600 font-bold tracking-wider uppercase text-xs">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {companies.map((company) => (
                    <tr key={company.id}>
                      <td className="border-dashed border-t border-gray-200 px-6 py-4">{company.companyName}</td>
                      <td className="border-dashed border-t border-gray-200 px-6 py-4">{company.companyCode}</td>
                      <td className="border-dashed border-t border-gray-200 px-6 py-4">{new Date(company.dateModified).toLocaleString()}</td>
                      <td className="border-dashed border-t border-gray-200 px-6 py-4">{new Date(company.dateCreated).toLocaleString()}</td>
                      <td className="border-dashed border-t border-gray-200 px-6 py-4">
                        <Link to={`/edit-company/${company.id}`} className="text-indigo-600 hover:text-indigo-900 mr-4">
                          <Edit size={18} />
                        </Link>
                        <button 
                          onClick={() => handleDeleteCompany(company.id)} 
                          className="text-red-600 hover:text-red-900"
                          disabled={isDeleting}
                        >
                          <Trash2 size={18} />
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
          
          <div className="flex justify-between items-center mt-6">
            <span className="text-sm text-gray-700">
              Page {currentPage}
            </span>
            <div className="flex">
              <button
                onClick={handlePreviousPage}
                disabled={currentPage === 1}
                className="px-3 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 disabled:opacity-50"
              >
                <ChevronLeft size={20} />
              </button>
              <button
                onClick={handleNextPage}
                disabled={!lastKey}
                className="px-3 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 disabled:opacity-50"
              >
                <ChevronRight size={20} />
              </button>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default CompanyList;