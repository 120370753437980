import React, { useState, useEffect, useRef } from 'react';
import { ref, query, orderByChild, get, update, push } from 'firebase/database';
import { db, auth } from '../firebase';
import { Link, useNavigate } from 'react-router-dom';
import { 
  Edit, 
  Trash2, 
  ChevronLeft, 
  ChevronRight, 
  Plus, 
  AlertCircle, 
  Upload, 
  Search, 
  Download,
  ArrowUpDown,
  ArrowUp,
  ArrowDown
} from 'lucide-react';
import Papa from 'papaparse';
import Select from 'react-select';

const StoreList = () => {
  // State Management
  const [stores, setStores] = useState([]);
  const [companies, setCompanies] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [isDuplicateChecking, setIsDuplicateChecking] = useState(false);
  const fileInputRef = useRef(null);

  // Filter and Sort States
  const [searchTerm, setSearchTerm] = useState('');
  const [filters, setFilters] = useState({
    parish: '',
    channel: '',
    company: ''
  });
  const [sortConfig, setSortConfig] = useState({
    key: 'dateModified',
    direction: 'desc'
  });

  // Sort options
  const sortOptions = [
    { value: 'dateModified-desc', label: 'Most Recent' },
    { value: 'dateModified-asc', label: 'Oldest First' },
    { value: 'storeName-asc', label: 'Name (A-Z)' },
    { value: 'storeName-desc', label: 'Name (Z-A)' },
    { value: 'parish-asc', label: 'Parish (A-Z)' },
    { value: 'parish-desc', label: 'Parish (Z-A)' },
    { value: 'channel-asc', label: 'Channel (A-Z)' },
    { value: 'channel-desc', label: 'Channel (Z-A)' }
  ];

  // Constants
  const PAGE_SIZE = 50;
  const navigate = useNavigate();

  // Options for filters
  const parishes = [
    'Kingston', 'St. Andrew', 'St. Catherine', 'Clarendon', 'Manchester',
    'St. Elizabeth', 'Westmoreland', 'Hanover', 'St. James', 'Trelawny',
    'St. Ann', 'St. Mary', 'Portland', 'St. Thomas'
  ];

  const channels = ['Supermarket', 'Wholesale', 'Bar', 'Pharmacy', 'Gas Station'];

  // Sample CSV data
  const sampleCsvData = [
    {
      storeName: "Sample Hilo",
      companyName: "Carreras",
      parish: "Kingston",
      searchLocation: "15 Hope Road Kingston Jamaica",
      channel: "Supermarket"
    },
    {
      storeName: "Sample Megamart",
      companyName: "Carreras",
      parish: "St. Andrew",
      searchLocation: "15 Waterloo Road Kingston Jamaica",
      channel: "Wholesale"
    }
  ];

  useEffect(() => {
    fetchAllStores();
    fetchCompanies();
  }, []);

  // Sorting Functions
  const handleSort = (option) => {
    const [key, direction] = option.value.split('-');
    setSortConfig({ key, direction });
    setCurrentPage(1);
  };

  const handleColumnSort = (key) => {
    setSortConfig(current => ({
      key,
      direction: current.key === key && current.direction === 'asc' ? 'desc' : 'asc'
    }));
    setCurrentPage(1);
  };

  const getSortIcon = (columnKey) => {
    if (sortConfig.key !== columnKey) {
      return <ArrowUpDown size={16} className="text-gray-400" />;
    }
    return sortConfig.direction === 'asc' 
      ? <ArrowUp size={16} className="text-blue-500" />
      : <ArrowDown size={16} className="text-blue-500" />;
  };

  const getSortedStores = (stores) => {
    if (!sortConfig.key) return stores;

    return [...stores].sort((a, b) => {
      let aValue = a[sortConfig.key];
      let bValue = b[sortConfig.key];

      // Handle company names
      if (sortConfig.key === 'company') {
        aValue = companies[a.company]?.companyName || '';
        bValue = companies[b.company]?.companyName || '';
      }

      // Case insensitive comparison for strings
      if (typeof aValue === 'string') {
        aValue = aValue.toLowerCase();
        bValue = bValue.toLowerCase();
      }

      if (aValue < bValue) {
        return sortConfig.direction === 'asc' ? -1 : 1;
      }
      if (aValue > bValue) {
        return sortConfig.direction === 'asc' ? 1 : -1;
      }
      return 0;
    });
  };

  const handleSelectAll = (e) => {
    setSelectAll(e.target.checked);
    if (e.target.checked) {
      setSelectedItems(getCurrentPageStores().map(item => item.id));
    } else {
      setSelectedItems([]);
    }
  };

  const handleSelectItem = (storeId) => {
    setSelectedItems(prevSelected => {
      if (prevSelected.includes(storeId)) {
        return prevSelected.filter(id => id !== storeId);
      } else {
        return [...prevSelected, storeId];
      }
    });
  };

  // Utility function to find duplicate stores
  const findDuplicateStores = (stores) => {
    const storeGroups = stores.reduce((acc, store) => {
      const key = [
        store.storeName.toLowerCase().trim(),
        store.company,
        store.searchLocation.toLowerCase().trim(),
        store.parish
      ].join('|');
      
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(store);
      return acc;
    }, {});

    return Object.values(storeGroups)
      .filter(group => group.length > 1)
      .flat();
  };

  // Function to handle duplicate detection and removal
  const handleFindDuplicates = async () => {
    setIsDuplicateChecking(true);
    setError('');
    try {
      const duplicates = findDuplicateStores(stores);
      if (duplicates.length === 0) {
        alert('No duplicates found!');
        return;
      }

      if (window.confirm(`Found ${duplicates.length} duplicate stores. Would you like to remove them?`)) {
        const storeMap = new Map();
        const updates = {};

        // Keep the most recently modified version of each store
        duplicates.forEach(store => {
          const key = [
            store.storeName.toLowerCase().trim(),
            store.company,
            store.searchLocation.toLowerCase().trim(),
            store.parish
          ].join('|');

          if (!storeMap.has(key)) {
            storeMap.set(key, store);
          } else {
            const existing = storeMap.get(key);
            const storeToKeep = new Date(existing.dateModified) > new Date(store.dateModified) 
              ? existing 
              : store;
            const storeToDelete = storeToKeep === existing ? store : existing;
            
            storeMap.set(key, storeToKeep);
            updates[`stores/${storeToDelete.id}`] = {
              ...storeToDelete,
              isDeleted: true,
              dateModified: new Date().toISOString()
            };
          }
        });

        await update(ref(db), updates);
        await fetchAllStores();
        alert(`Successfully removed ${Object.keys(updates).length} duplicate stores.`);
      }
    } catch (err) {
      console.error('Error handling duplicates:', err);
      setError('Failed to process duplicates. Please try again.');
    } finally {
      setIsDuplicateChecking(false);
    }
  };

  const handleBulkDelete = async () => {
    if (selectedItems.length === 0) {
      alert('Please select items to delete');
      return;
    }

    if (window.confirm(`Are you sure you want to delete ${selectedItems.length} stores?`)) {
      setIsDeleting(true);
      try {
        const updates = {};
        selectedItems.forEach(storeId => {
          updates[`stores/${storeId}`] = {
            ...stores.find(store => store.id === storeId),
            isDeleted: true,
            dateModified: new Date().toISOString()
          };
        });

        await update(ref(db), updates);
        await fetchAllStores();
        setSelectedItems([]);
        setSelectAll(false);
        alert('Stores deleted successfully');
      } catch (err) {
        console.error('Error deleting stores:', err);
        setError('Failed to delete stores. Please try again.');
      } finally {
        setIsDeleting(false);
      }
    }
  };

  const fetchAllStores = async () => {
    setIsLoading(true);
    setError('');
    try {
      if (!auth.currentUser) {
        throw new Error('You must be logged in to view stores.');
      }
      
      const storesRef = ref(db, 'stores');
      const storesQuery = query(storesRef, orderByChild('dateCreated'));
      const snapshot = await get(storesQuery);
      
      if (snapshot.exists()) {
        const storesData = [];
        snapshot.forEach((childSnapshot) => {
          const storeData = childSnapshot.val();
          if (!storeData.isDeleted) {
            storesData.push({ id: childSnapshot.key, ...storeData });
          }
        });
        setStores(storesData);
      } else {
        setStores([]);
      }
    } catch (err) {
      console.error('Error fetching stores:', err);
      setError(err.message || 'Failed to fetch stores. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const fetchCompanies = async () => {
    try {
      const companiesRef = ref(db, 'companies');
      const snapshot = await get(companiesRef);
      if (snapshot.exists()) {
        const companiesData = {};
        snapshot.forEach((childSnapshot) => {
          const companyData = childSnapshot.val();
          companiesData[childSnapshot.key] = companyData;
        });
        setCompanies(companiesData);
      }
    } catch (err) {
      console.error('Error fetching companies:', err);
      setError('Failed to fetch companies. Some company names may not display correctly.');
    }
  };

  const findCompanyIdByName = (name) => {
    return Object.entries(companies).find(
      ([_, company]) => company.companyName.toLowerCase() === name.toLowerCase()
    )?.[0];
  };

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      setIsUploading(true);
      setError('');
      Papa.parse(file, {
        complete: handleFileParseComplete,
        header: true,
        error: (error) => {
          setError(`Error parsing file: ${error.message}`);
          setIsUploading(false);
        }
      });
    }
  };

  const handleFileParseComplete = async (results) => {
    try {
      const { data } = results;
      const storesRef = ref(db, 'stores');
      const updates = {};
      const errors = [];
      let rowNum = 0;
      const newStores = [];

      for (const row of data) {
        rowNum++;
        if (!row.storeName || !row.companyName || !row.parish || !row.searchLocation || !row.channel) {
          errors.push(`Row ${rowNum}: Missing required fields`);
          continue;
        }

        if (!parishes.includes(row.parish)) {
          errors.push(`Row ${rowNum}: Invalid parish "${row.parish}"`);
          continue;
        }

        if (!channels.includes(row.channel)) {
          errors.push(`Row ${rowNum}: Invalid channel "${row.channel}"`);
          continue;
        }

        const companyId = findCompanyIdByName(row.companyName);
        if (!companyId) {
          errors.push(`Row ${rowNum}: Company "${row.companyName}" not found`);
          continue;
        }

        const newStore = {
          storeName: row.storeName.trim(),
          company: companyId,
          parish: row.parish,
          searchLocation: row.searchLocation.trim(),
          channel: row.channel,
          dateCreated: new Date().toISOString(),
          dateModified: new Date().toISOString(),
          createdBy: auth.currentUser.uid,
          latitude: 0,
          longitude: 0
        };

        // Check for duplicates in existing stores and new data
        const isDuplicate = [...stores, ...newStores].some(store => 
          store.storeName.toLowerCase().trim() === newStore.storeName.toLowerCase().trim() &&
          store.company === newStore.company &&
          store.searchLocation.toLowerCase().trim() === newStore.searchLocation.toLowerCase().trim() &&
          store.parish === newStore.parish
        );

        if (isDuplicate) {
          errors.push(`Row ${rowNum}: Duplicate store entry detected`);
          continue;
        }

        newStores.push(newStore);
      }

      if (errors.length > 0) {
        setError(`Upload errors:\n${errors.join('\n')}`);
        return;
      }

      if (newStores.length === 0) {
        setError('No valid stores found in the CSV file');
        return;
      }

      // Add new stores to updates object
      newStores.forEach(store => {
        const newStoreRef = push(storesRef);
        updates[newStoreRef.key] = store;
      });

      await update(storesRef, updates);
      alert(`Successfully uploaded ${Object.keys(updates).length} stores.`);
      fetchAllStores();
    } catch (error) {
      console.error('Error uploading stores:', error);
      setError('Failed to upload stores. Please try again.');
    } finally {
      setIsUploading(false);
    }
  };

  const handleDeleteStore = async (storeId) => {
    if (window.confirm('Are you sure you want to delete this store?')) {
      setIsDeleting(true);
      try {
        const storeRef = ref(db, `stores/${storeId}`);
        await update(storeRef, { 
          isDeleted: true, 
          dateModified: new Date().toISOString() 
        });
        await fetchAllStores();
        alert('Store deleted successfully');
      } catch (err) {
        console.error('Error deleting store:', err);
        setError('Failed to delete store. Please try again.');
      } finally {
        setIsDeleting(false);
      }
    }
  };

  const downloadSampleCsv = () => {
    const headerRow = {
      storeName: "Store Name (Required)",
      companyName: "Company Name (Required - Must match existing company)",
      parish: "Parish (Required - Must be valid Jamaica parish)",
      searchLocation: "Store Address (Required)",
      channel: "Channel (Required - Must be Supermarket/Wholesale/Bar/Pharmacy)"
    };

    const csvData = [headerRow, ...sampleCsvData];
    const csv = Papa.unparse(csvData);
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', 'store_template.csv');
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  const getFilteredStores = () => {
    const filtered = stores.filter(store => {
      const matchesSearch = !searchTerm || 
        store.storeName.toLowerCase().includes(searchTerm.toLowerCase()) ||
        store.searchLocation.toLowerCase().includes(searchTerm.toLowerCase());
      
      const matchesParish = !filters.parish || store.parish === filters.parish;
      const matchesChannel = !filters.channel || store.channel === filters.channel;
      const matchesCompany = !filters.company || store.company === filters.company;

      return matchesSearch && matchesParish && matchesChannel && matchesCompany;
    });

    return getSortedStores(filtered);
  };

  const getCurrentPageStores = () => {
    const filteredStores = getFilteredStores();
    const startIndex = (currentPage - 1) * PAGE_SIZE;
    return filteredStores.slice(startIndex, startIndex + PAGE_SIZE);
  };

  const handleNextPage = () => {
    const filteredStores = getFilteredStores();
    if (currentPage < Math.ceil(filteredStores.length / PAGE_SIZE)) {
      setCurrentPage(currentPage + 1);
      setSelectAll(false);
      setSelectedItems([]);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
      setSelectAll(false);
      setSelectedItems([]);
    }
  };

  return (
    <div className="container mx-auto px-4 sm:px-6 lg:px-8 py-8 text-left">
      {/* Header Section */}
      <div className="flex flex-col sm:flex-row justify-between items-center mb-6">
        <h2 className="text-2xl font-semibold leading-tight mb-2 sm:mb-0">Store List</h2>
        <div className="flex flex-wrap gap-2">
          <button
            onClick={downloadSampleCsv}
            className="bg-purple-500 hover:bg-purple-700 text-white font-bold py-2 px-4 rounded flex items-center"
          >
            <Download className="mr-2" size={20} />
            Download Template
          </button>
          <button
            onClick={handleFindDuplicates}
            className="bg-yellow-500 hover:bg-yellow-700 text-white font-bold py-2 px-4 rounded flex items-center"
            disabled={isDuplicateChecking}
          >
            <AlertCircle className="mr-2" size={20} />
            {isDuplicateChecking ? 'Checking...' : 'Find Duplicates'}
          </button>
          <button
            onClick={() => fileInputRef.current.click()}
            className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded flex items-center"
            disabled={isUploading}
          >
            <Upload className="mr-2" size={20} />
            {isUploading ? 'Uploading...' : 'Upload CSV'}
          </button>
          <input
            type="file"
            ref={fileInputRef}
            onChange={handleFileUpload}
            accept=".csv"
            className="hidden"
          />
          <button
            onClick={() => navigate('/add-store')}
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded flex items-center"
          >
            <Plus className="mr-2" size={20} />
            Add Store
          </button>
          {selectedItems.length > 0 && (
            <button
              onClick={handleBulkDelete}
              className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded flex items-center"
              disabled={isDeleting}
            >
              <Trash2 className="mr-2" size={20} />
              Delete ({selectedItems.length})
            </button>
          )}
        </div>
      </div>

      {/* Search, Sort, and Filters Section */}
      <div className="mb-6 grid grid-cols-1 md:grid-cols-5 gap-4">
        <div className="relative">
          <input
            type="text"
            placeholder="Search stores..."
            className="w-full px-4 py-2 border rounded-lg"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <Search className="absolute right-3 top-2.5 text-gray-400" size={20} />
        </div>

        <Select
          placeholder="Sort by..."
          options={sortOptions}
          onChange={handleSort}
          value={sortOptions.find(
            option => option.value === `${sortConfig.key}-${sortConfig.direction}`
          )}
          className="w-full"
        />
        
        <Select
          placeholder="Select Parish"
          options={parishes.map(p => ({ value: p, label: p }))}
          value={filters.parish ? { value: filters.parish, label: filters.parish } : null}
          onChange={(option) => setFilters({ ...filters, parish: option ? option.value : '' })}
          isClearable
          className="w-full"
        />

        <Select
          placeholder="Select Channel"
          options={channels.map(c => ({ value: c, label: c }))}
          value={filters.channel ? { value: filters.channel, label: filters.channel } : null}
          onChange={(option) => setFilters({ ...filters, channel: option ? option.value : '' })}
          isClearable
          className="w-full"
        />

        <Select
          placeholder="Select Company"
          options={Object.entries(companies).map(([id, company]) => ({
            value: id,
            label: company.companyName
          }))}
          value={filters.company ? {
            value: filters.company,
            label: companies[filters.company]?.companyName
          } : null}
          onChange={(option) => setFilters({ ...filters, company: option ? option.value : '' })}
          isClearable
          className="w-full"
        />
      </div>

      {error && (
        <div className="mb-4 p-3 bg-red-100 text-red-700 rounded flex items-center">
          <AlertCircle className="mr-2" size={20} />
          <pre className="whitespace-pre-wrap">{error}</pre>
        </div>
      )}

      {isLoading ? (
        <div className="flex justify-center items-center h-64">
          <div className="animate-spin rounded-full h-32 w-32 border-b-2 border-gray-900"></div>
        </div>
      ) : (
        <>
          <div className="overflow-x-auto bg-white rounded-lg shadow overflow-y-auto relative">
            <table className="border-collapse table-auto w-full whitespace-no-wrap bg-white table-striped relative">
              <thead>
                <tr className="text-left">
                  <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-3">
                    <input
                      type="checkbox"
                      checked={selectAll}
                      onChange={handleSelectAll}
                      className="form-checkbox h-4 w-4 text-blue-600"
                    />
                  </th>
                  <th 
                    className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-3 text-gray-600 font-bold tracking-wider uppercase text-xs cursor-pointer"
                    onClick={() => handleColumnSort('storeName')}
                  >
                    <div className="flex items-center">
                      Store Name {getSortIcon('storeName')}
                    </div>
                  </th>
                  <th 
                    className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-3 text-gray-600 font-bold tracking-wider uppercase text-xs cursor-pointer"
                    onClick={() => handleColumnSort('company')}
                  >
                    <div className="flex items-center">
                      Company {getSortIcon('company')}
                    </div>
                  </th>
                  <th 
                    className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-3 text-gray-600 font-bold tracking-wider uppercase text-xs cursor-pointer"
                    onClick={() => handleColumnSort('parish')}
                  >
                    <div className="flex items-center">
                      Parish {getSortIcon('parish')}
                    </div>
                  </th>
                  <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-3 text-gray-600 font-bold tracking-wider uppercase text-xs">
                    Search Location
                  </th>
                  <th 
                    className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-3 text-gray-600 font-bold tracking-wider uppercase text-xs cursor-pointer"
                    onClick={() => handleColumnSort('channel')}
                  >
                    <div className="flex items-center">
                      Channel {getSortIcon('channel')}
                    </div>
                  </th>
                  <th 
                    className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-3 text-gray-600 font-bold tracking-wider uppercase text-xs cursor-pointer"
                    onClick={() => handleColumnSort('dateModified')}
                  >
                    <div className="flex items-center">
                      Last Modified {getSortIcon('dateModified')}
                    </div>
                  </th>
                  <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-3 text-gray-600 font-bold tracking-wider uppercase text-xs">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody>
                {getCurrentPageStores().map((store) => (
                  <tr key={store.id}>
                    <td className="border-dashed border-t border-gray-200 px-6 py-4">
                      <input
                        type="checkbox"
                        checked={selectedItems.includes(store.id)}
                        onChange={() => handleSelectItem(store.id)}
                        className="form-checkbox h-4 w-4 text-blue-600"
                      />
                    </td>
                    <td className="border-dashed border-t border-gray-200 px-6 py-4">{store.storeName}</td>
                    <td className="border-dashed border-t border-gray-200 px-6 py-4">
                      {companies[store.company]?.companyName || 'Unknown'}
                    </td>
                    <td className="border-dashed border-t border-gray-200 px-6 py-4">{store.parish}</td>
                    <td className="border-dashed border-t border-gray-200 px-6 py-4">{store.searchLocation}</td>
                    <td className="border-dashed border-t border-gray-200 px-6 py-4">{store.channel}</td>
                    <td className="border-dashed border-t border-gray-200 px-6 py-4">
                      {new Date(store.dateModified).toLocaleString()}
                    </td>
                    <td className="border-dashed border-t border-gray-200 px-6 py-4">
                      <div className="flex space-x-3">
                        <Link 
                          to={`/edit-store/${store.id}`} 
                          className="text-indigo-600 hover:text-indigo-900"
                        >
                          <Edit size={18} />
                        </Link>
                        <button 
                          onClick={() => handleDeleteStore(store.id)} 
                          className="text-red-600 hover:text-red-900"
                          disabled={isDeleting}
                        >
                          <Trash2 size={18} />
                        </button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          
          {/* Pagination Controls */}
          <div className="flex justify-between items-center mt-6">
            <span className="text-sm text-gray-700">
              Page {currentPage} of {Math.ceil(getFilteredStores().length / PAGE_SIZE)} 
              {' '}({getFilteredStores().length} total stores)
              {selectedItems.length > 0 && ` • ${selectedItems.length} selected`}
            </span>
            <div className="flex space-x-2">
              <button
                onClick={handlePreviousPage}
                disabled={currentPage === 1}
                className="px-3 py-2 rounded-lg border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 disabled:opacity-50 disabled:cursor-not-allowed flex items-center"
              >
                <ChevronLeft size={20} />
                <span className="ml-1">Previous</span>
              </button>
              <button
                onClick={handleNextPage}
                disabled={currentPage >= Math.ceil(getFilteredStores().length / PAGE_SIZE)}
                className="px-3 py-2 rounded-lg border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 disabled:opacity-50 disabled:cursor-not-allowed flex items-center"
              >
                <span className="mr-1">Next</span>
                <ChevronRight size={20} />
              </button>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default StoreList;