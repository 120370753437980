import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { ref, get, update } from 'firebase/database';
import { db } from '../firebase';
import { Save, AlertCircle } from 'lucide-react';

const EditCompany = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [company, setCompany] = useState({
    companyName: '',
    companyCode: '',
  });
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchCompany = async () => {
      setIsLoading(true);
      setError('');
      try {
        const companyRef = ref(db, `companies/${id}`);
        const snapshot = await get(companyRef);
        if (snapshot.exists()) {
          setCompany(snapshot.val());
        } else {
          setError('No such company!');
        }
      } catch (err) {
        console.error('Error fetching company:', err);
        setError('Failed to fetch company details. Please try again.');
      } finally {
        setIsLoading(false);
      }
    };

    fetchCompany();
  }, [id]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCompany({ ...company, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setIsLoading(true);
    try {
      const companyRef = ref(db, `companies/${id}`);
      await update(companyRef, {
        ...company,
        dateModified: new Date().toISOString(),
      });
      console.log('Company updated successfully');
      navigate('/companies'); // Updated to match the route in App.js
    } catch (err) {
      console.error('Error updating company: ', err);
      setError('Failed to update company. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading && !company.companyName) {
    return <div className="text-center mt-10">Loading...</div>;
  }

  return (
    <div className="max-w-md mx-auto mt-10 p-6 bg-white rounded-lg shadow-xl text-left">
      <h2 className="text-2xl font-bold mb-6 text-gray-800">Edit Company</h2>
      {error && (
        <div className="mb-4 p-3 bg-red-100 text-red-700 rounded flex items-center">
          <AlertCircle className="mr-2" size={20} />
          {error}
        </div>
      )}
      <form onSubmit={handleSubmit} className="space-y-4">
        <div>
          <label htmlFor="companyName" className="block text-sm font-medium text-gray-700">Company Name:</label>
          <input
            type="text"
            id="companyName"
            name="companyName"
            value={company.companyName}
            onChange={handleInputChange}
            required
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
          />
        </div>
        <div>
          <label htmlFor="companyCode" className="block text-sm font-medium text-gray-700">Company Code:</label>
          <input
            type="text"
            id="companyCode"
            name="companyCode"
            value={company.companyCode}
            onChange={handleInputChange}
            required
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
          />
        </div>
        <button
          type="submit"
          className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          disabled={isLoading}
        >
          {isLoading ? (
            <span className="flex items-center">
              <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
              </svg>
              Updating...
            </span>
          ) : (
            <>
              <Save className="mr-2" size={20} />
              Update Company
            </>
          )}
        </button>
      </form>
    </div>
  );
};

export default EditCompany;