import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Store, Package, List, Users } from 'lucide-react';
import { db } from '../firebase';
import { ref, get } from 'firebase/database';

const DashboardCard = ({ title, icon: Icon, link, count }) => (
  <Link to={link} className="block p-6 bg-white rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300 ease-in-out">
    <div className="flex items-center justify-between">
      <div>
        <h2 className="text-2xl font-bold mb-2 text-gray-800">{title}</h2>
        <p className="text-gray-600">Total: {count}</p>
      </div>
      <Icon className="w-12 h-12 text-gray-500" />
    </div>
  </Link>
);

const Dashboard = () => {
  const [counts, setCounts] = useState({
    stores: 0,
    products: 0,
    categories: 0,
    users: 0,
  });

  useEffect(() => {
    const fetchCounts = async () => {
      const modules = ['stores', 'products', 'categories', 'users'];
      const newCounts = {};

      for (const module of modules) {
        const moduleRef = ref(db, module);
        const snapshot = await get(moduleRef);
        newCounts[module] = snapshot.exists() ? Object.keys(snapshot.val()).length : 0;
      }

      setCounts(newCounts);
    };

    fetchCounts();
  }, []);

  const cards = [
    { title: 'Stores', icon: Store, link: '/stores', count: counts.stores },
    { title: 'Products', icon: Package, link: '/products', count: counts.products },
    { title: 'Categories', icon: List, link: '/categories', count: counts.categories },
    { title: 'Company Users', icon: Users, link: '/users', count: counts.users },
  ];

  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-6 text-gray-800">Dashboard</h1>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {cards.map((card, index) => (
          <DashboardCard key={index} {...card} />
        ))}
      </div>
    </div>
  );
};

export default Dashboard;