import React, { useState, useEffect } from 'react';
import { ref, push, set, get } from 'firebase/database';
import { db, auth } from '../firebase';
import { Plus, AlertCircle } from 'lucide-react';
import { useNavigate } from 'react-router-dom';

const AddCategory = () => {
  const [category, setCategory] = useState({ categoryName: '', companyId: '' });
  const [companies, setCompanies] = useState([]);
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCompanies = async () => {
      try {
        const companiesRef = ref(db, 'companies');
        const snapshot = await get(companiesRef);
        if (snapshot.exists()) {
          const companiesData = Object.entries(snapshot.val()).map(([id, data]) => ({
            id,
            ...data
          }));
          setCompanies(companiesData);
        }
      } catch (error) {
        console.error('Error fetching companies:', error);
        setError('Failed to fetch companies. Please try again.');
      }
    };

    fetchCompanies();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCategory({ ...category, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setIsLoading(true);
    try {
      if (!auth.currentUser) {
        throw new Error('You must be logged in to add a category.');
      }
      const newCategoryRef = push(ref(db, 'categories'));
      await set(newCategoryRef, {
        ...category,
        dateCreated: new Date().toISOString(),
        dateModified: new Date().toISOString(),
        createdBy: auth.currentUser.uid,
      });
      console.log('Category added with ID: ', newCategoryRef.key);
      navigate('/categories');
    } catch (error) {
      console.error('Error adding category: ', error);
      setError(error.message || 'Failed to add category. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="max-w-md mx-auto mt-10 p-6 bg-white rounded-lg shadow-xl text-left">
      <h2 className="text-2xl font-bold mb-6 text-gray-800">Add Category</h2>
      {error && (
        <div className="mb-4 p-3 bg-red-100 text-red-700 rounded flex items-center">
          <AlertCircle className="mr-2" size={20} />
          {error}
        </div>
      )}
      <form onSubmit={handleSubmit} className="space-y-4">
        <div>
          <label htmlFor="companyId" className="block text-sm font-medium text-gray-700">Company:</label>
          <select
            id="companyId"
            name="companyId"
            value={category.companyId}
            onChange={handleInputChange}
            required
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
          >
            <option value="">Select a company</option>
            {companies.map((company) => (
              <option key={company.id} value={company.id}>
                {company.companyName}
              </option>
            ))}
          </select>
        </div>
        <div>
          <label htmlFor="categoryName" className="block text-sm font-medium text-gray-700">Category Name:</label>
          <input
            type="text"
            id="categoryName"
            name="categoryName"
            value={category.categoryName}
            onChange={handleInputChange}
            required
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
          />
        </div>
        <button 
          type="submit" 
          className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          disabled={isLoading}
        >
          {isLoading ? 'Adding...' : (
            <>
              <Plus className="mr-2" size={20} />
              Add Category
            </>
          )}
        </button>
      </form>
    </div>
  );
};

export default AddCategory;