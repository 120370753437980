import React, { useState, useEffect, useCallback } from 'react';
import { ref, query, orderByChild, limitToLast, get } from 'firebase/database';
import { db, auth } from '../firebase';
import { ChevronLeft, ChevronRight, AlertCircle, Filter, Eye } from 'lucide-react';
import { Dialog, Transition } from '@headlessui/react';

const ActivityServerList = () => {
  const [activities, setActivities] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [filterPlatform, setFilterPlatform] = useState('all');
  const [filterType, setFilterType] = useState('all');
  const [selectedActivity, setSelectedActivity] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const pageSize = 20;

  const fetchActivities = useCallback(async () => {
    setIsLoading(true);
    setError('');
    try {
      const user = auth.currentUser;
      if (!user) throw new Error('You must be logged in to view activities.');

      const activitiesRef = ref(db, 'activities');
      let activitiesQuery = query(
        activitiesRef,
        orderByChild('dateCreated'),
        limitToLast(pageSize * currentPage)
      );

      const snapshot = await get(activitiesQuery);
      if (snapshot.exists()) {
        let activitiesData = [];
        snapshot.forEach((childSnapshot) => {
          activitiesData.push({ id: childSnapshot.key, ...childSnapshot.val() });
        });
        activitiesData = activitiesData.reverse();

        // Apply filters
        if (filterPlatform !== 'all') {
          activitiesData = activitiesData.filter(activity => activity.platform === filterPlatform);
        }
        if (filterType !== 'all') {
          activitiesData = activitiesData.filter(activity => activity.type === filterType);
        }

        setActivities(activitiesData);
        setHasMore(activitiesData.length === pageSize * currentPage);
      } else {
        setActivities([]);
        setHasMore(false);
      }
    } catch (err) {
      console.error('Error fetching activities:', err);
      setError(err.message || 'Failed to fetch activities. Please try again.');
    } finally {
      setIsLoading(false);
    }
  }, [currentPage, filterPlatform, filterType]);

  useEffect(() => {
    fetchActivities();
  }, [fetchActivities]);

  const handleNextPage = useCallback(() => {
    if (hasMore) {
      setCurrentPage(prevPage => prevPage + 1);
    }
  }, [hasMore]);

  const handlePreviousPage = useCallback(() => {
    if (currentPage > 1) {
      setCurrentPage(prevPage => prevPage - 1);
    }
  }, [currentPage]);

  const handleFilterChange = useCallback((e) => {
    const { name, value } = e.target;
    if (name === 'platform') {
      setFilterPlatform(value);
    } else if (name === 'type') {
      setFilterType(value);
    }
    setCurrentPage(1);
  }, []);

  const openActivityModal = useCallback((activity) => {
    setSelectedActivity(activity);
    setIsModalOpen(true);
  }, []);

  const closeActivityModal = useCallback(() => {
    setSelectedActivity(null);
    setIsModalOpen(false);
  }, []);

  if (isLoading && activities.length === 0) {
    return <div className="text-center mt-10">Loading...</div>;
  }

  return (
    <div className="container mx-auto px-4 sm:px-6 lg:px-8 py-8">
      <h1 className="text-2xl font-semibold mb-6">Activity Server List</h1>
      
      {error && (
        <div className="mb-4 p-3 bg-red-100 text-red-700 rounded flex items-center">
          <AlertCircle className="mr-2" size={20} />
          {error}
        </div>
      )}
      
      <div className="mb-4 flex flex-wrap items-center space-x-4">
        <div className="flex items-center">
          <Filter className="mr-2" size={20} />
          <select
            name="platform"
            value={filterPlatform}
            onChange={handleFilterChange}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
          >
            <option value="all">All Platforms</option>
            <option value="admin">Admin Panel</option>
            <option value="customer">Customer Portal</option>
            <option value="ios">iOS App</option>
            <option value="android">Android App</option>
          </select>
        </div>
        <div className="flex items-center">
          <Filter className="mr-2" size={20} />
          <select
            name="type"
            value={filterType}
            onChange={handleFilterChange}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
          >
            <option value="all">All Types</option>
            <option value="login">Login</option>
            <option value="logout">Logout</option>
            <option value="create">Create</option>
            <option value="update">Update</option>
            <option value="delete">Delete</option>
          </select>
        </div>
      </div>
      
      {activities.length === 0 ? (
        <div className="text-center text-gray-500 mt-4">No activities found.</div>
      ) : (
        <div className="overflow-x-auto bg-white rounded-lg shadow overflow-y-auto relative">
          <table className="border-collapse table-auto w-full whitespace-no-wrap bg-white table-striped relative">
            <thead>
              <tr className="text-left">
                <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-3 text-gray-600 font-bold tracking-wider uppercase text-xs">Platform</th>
                <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-3 text-gray-600 font-bold tracking-wider uppercase text-xs">Type</th>
                <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-3 text-gray-600 font-bold tracking-wider uppercase text-xs">User ID</th>
                <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-3 text-gray-600 font-bold tracking-wider uppercase text-xs">Date</th>
                <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-3 text-gray-600 font-bold tracking-wider uppercase text-xs">Actions</th>
              </tr>
            </thead>
            <tbody>
              {activities.map((activity) => (
                <tr key={activity.id}>
                  <td className="border-dashed border-t border-gray-200 px-6 py-4">{activity.platform}</td>
                  <td className="border-dashed border-t border-gray-200 px-6 py-4">{activity.type}</td>
                  <td className="border-dashed border-t border-gray-200 px-6 py-4">{activity.userId}</td>
                  <td className="border-dashed border-t border-gray-200 px-6 py-4">{new Date(activity.dateCreated).toLocaleString()}</td>
                  <td className="border-dashed border-t border-gray-200 px-6 py-4">
                    <button
                      onClick={() => openActivityModal(activity)}
                      className="text-indigo-600 hover:text-indigo-900"
                    >
                      <Eye size={18} />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
      
      <div className="flex justify-between items-center mt-6">
        <button
          onClick={handlePreviousPage}
          disabled={currentPage === 1}
          className="px-3 py-2 rounded-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 disabled:opacity-50"
        >
          <ChevronLeft size={20} />
        </button>
        <span className="text-sm text-gray-700">
          Page {currentPage}
        </span>
        <button
          onClick={handleNextPage}
          disabled={!hasMore}
          className="px-3 py-2 rounded-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 disabled:opacity-50"
        >
          <ChevronRight size={20} />
        </button>
      </div>

      <Transition show={isModalOpen} as={React.Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-10 overflow-y-auto"
          onClose={closeActivityModal}
        >
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={React.Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />
            </Transition.Child>

            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={React.Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                <Dialog.Title
                  as="h3"
                  className="text-lg font-medium leading-6 text-gray-900"
                >
                  Activity Details
                </Dialog.Title>
                {selectedActivity && (
                  <div className="mt-2">
                    <p className="text-sm text-gray-500">
                      <strong>ID:</strong> {selectedActivity.id}
                    </p>
                    <p className="text-sm text-gray-500">
                      <strong>Platform:</strong> {selectedActivity.platform}
                    </p>
                    <p className="text-sm text-gray-500">
                      <strong>Type:</strong> {selectedActivity.type}
                    </p>
                    <p className="text-sm text-gray-500">
                      <strong>User ID:</strong> {selectedActivity.userId}
                    </p>
                    <p className="text-sm text-gray-500">
                      <strong>Date Created:</strong> {new Date(selectedActivity.dateCreated).toLocaleString()}
                    </p>
                    <p className="text-sm text-gray-500">
                      <strong>Date Modified:</strong> {new Date(selectedActivity.dateModified).toLocaleString()}
                    </p>
                    <p className="text-sm text-gray-500">
                      <strong>Description:</strong> {selectedActivity.description}
                    </p>
                  </div>
                )}

                <div className="mt-4">
                  <button
                    type="button"
                    className="inline-flex justify-center px-4 py-2 text-sm font-medium text-blue-900 bg-blue-100 border border-transparent rounded-md hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                    onClick={closeActivityModal}
                  >
                    Close
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
};

export default ActivityServerList;