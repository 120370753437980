import React, { useState, useEffect, useCallback } from 'react';
import { ref, get, onValue } from 'firebase/database';
import { db, auth } from '../firebase';
import { Link, useNavigate } from 'react-router-dom';
import { 
  Edit, 
  ChevronLeft, 
  ChevronRight, 
  Plus, 
  AlertCircle, 
  Search,
  ArrowUpDown,
  ArrowUp,
  ArrowDown
} from 'lucide-react';
import Select from 'react-select';

const UserList = () => {
  // State Management
  const [users, setUsers] = useState([]);
  const [companies, setCompanies] = useState({});
  const [lastKey, setLastKey] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const [totalItems, setTotalItems] = useState(0);

  // Filter and Sort States
  const [filters, setFilters] = useState({
    company: '',
    role: '',
    status: ''
  });
  const [sortConfig, setSortConfig] = useState({
    key: 'dateCreated',
    direction: 'desc'
  });

  const pageSize = 50;
  const navigate = useNavigate();

  // Sort options
  const sortOptions = [
    { value: 'dateCreated-desc', label: 'Most Recent' },
    { value: 'dateCreated-asc', label: 'Oldest First' },
    { value: 'fullName-asc', label: 'Name (A-Z)' },
    { value: 'fullName-desc', label: 'Name (Z-A)' },
    { value: 'email-asc', label: 'Email (A-Z)' },
    { value: 'email-desc', label: 'Email (Z-A)' },
    { value: 'role-asc', label: 'Role (A-Z)' },
    { value: 'role-desc', label: 'Role (Z-A)' }
  ];

  const roleOptions = [
    { value: 'admin', label: 'Admin' },
    { value: 'Merchandiser and Manager', label: 'Merchandiser and Manager' },
    { value: 'Merchandiser', label: 'Merchandiser' },
    { value: 'Manager', label: 'Manager' }
  ];

  // Sorting Functions
  const handleSort = (option) => {
    const [key, direction] = option.value.split('-');
    setSortConfig({ key, direction });
    setCurrentPage(1);
  };

  const handleColumnSort = (key) => {
    setSortConfig(current => ({
      key,
      direction: current.key === key && current.direction === 'asc' ? 'desc' : 'asc'
    }));
    setCurrentPage(1);
  };

  const getSortIcon = (columnKey) => {
    if (sortConfig.key !== columnKey) {
      return <ArrowUpDown size={16} className="text-gray-400" />;
    }
    return sortConfig.direction === 'asc' 
      ? <ArrowUp size={16} className="text-blue-500" />
      : <ArrowDown size={16} className="text-blue-500" />;
  };

  const getSortedUsers = (users) => {
    if (!sortConfig.key) return users;
  
    return [...users].sort((a, b) => {
      let aValue = a[sortConfig.key];
      let bValue = b[sortConfig.key];
  
      // Handle company names
      if (sortConfig.key === 'company') {
        aValue = companies[a.companyId] || '';
        bValue = companies[b.companyId] || '';
      }
  
      // Check if values are strings
      if (typeof aValue === 'string' && typeof bValue === 'string') {
        aValue = aValue.toLowerCase();
        bValue = bValue.toLowerCase();
      }
      // Handle date values
      else if (sortConfig.key === 'dateCreated' || sortConfig.key === 'dateModified') {
        aValue = new Date(aValue || 0).getTime();
        bValue = new Date(bValue || 0).getTime();
      }
      // Handle null/undefined values
      else {
        aValue = aValue || '';
        bValue = bValue || '';
      }
  
      if (aValue < bValue) {
        return sortConfig.direction === 'asc' ? -1 : 1;
      }
      if (aValue > bValue) {
        return sortConfig.direction === 'asc' ? 1 : -1;
      }
      return 0;
    });
  };

  const fetchCompanies = async () => {
    try {
      const companiesRef = ref(db, 'companies');
      const snapshot = await get(companiesRef);
      if (snapshot.exists()) {
        const companiesData = {};
        snapshot.forEach((childSnapshot) => {
          companiesData[childSnapshot.key] = childSnapshot.val().companyName;
        });
        setCompanies(companiesData);
      }
    } catch (error) {
      console.error('Error fetching companies:', error);
      setError('Failed to fetch companies. Please try again.');
    }
  };

  const fetchUsers = useCallback(async () => {
    setIsLoading(true);
    setError('');
    try {
      if (!auth.currentUser) {
        throw new Error('You must be logged in to view users.');
      }
      
      const usersRef = ref(db, 'users');
      
      onValue(usersRef, (snapshot) => {
        if (snapshot.exists()) {
          const usersData = [];
          snapshot.forEach((childSnapshot) => {
            usersData.push({ id: childSnapshot.key, ...childSnapshot.val() });
          });
          
          // Filter users based on search and filters
          const filteredUsers = usersData.filter(user => {
            const matchesSearch = !searchTerm || 
              user.fullName?.toLowerCase().includes(searchTerm.toLowerCase()) ||
              user.email?.toLowerCase().includes(searchTerm.toLowerCase());
            
            const matchesCompany = !filters.company || user.companyId === filters.company;
            const matchesRole = !filters.role || user.role === filters.role;
            const matchesStatus = !filters.status || user.accessPrivileges === filters.status;

            return matchesSearch && matchesCompany && matchesRole && matchesStatus;
          });

          // Get sorted users
          const sortedUsers = getSortedUsers(filteredUsers);

          setTotalItems(sortedUsers.length);

          // Implement pagination
          const startIndex = (currentPage - 1) * pageSize;
          const paginatedUsers = sortedUsers.slice(startIndex, startIndex + pageSize);
          
          setUsers(paginatedUsers);
          setLastKey(sortedUsers.length > startIndex + pageSize ? startIndex + pageSize : null);
        } else {
          setUsers([]);
          setLastKey(null);
          setTotalItems(0);
        }
        setIsLoading(false);
      });

    } catch (err) {
      console.error('Error fetching users:', err);
      setError(err.message || 'Failed to fetch users. Please try again.');
      setIsLoading(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
}, [currentPage, pageSize, searchTerm, filters]);

  useEffect(() => {
    fetchCompanies();
    fetchUsers();
    
    return () => {
      const usersRef = ref(db, 'users');
      onValue(usersRef, () => {}, { onlyOnce: true });
    };
  }, [fetchUsers]);

  const handleNextPage = () => {
    if (lastKey) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  // Navigation and UI Elements
  return (
    <div className="container mx-auto px-4 sm:px-6 lg:px-8 py-8 text-left">
      <div className="flex flex-col sm:flex-row justify-between items-center mb-6">
        <h2 className="text-2xl font-semibold leading-tight mb-2 sm:mb-0">User List</h2>
        <div className="flex space-x-2">
          <button
            onClick={() => navigate('/add-user')}
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded flex items-center"
          >
            <Plus className="mr-2" size={20} />
            Add User
          </button>
        </div>
      </div>

      {/* Search, Sort, and Filters Section */}
      <div className="mb-6 grid grid-cols-1 md:grid-cols-4 gap-4">
        <div className="relative">
          <input
            type="text"
            placeholder="Search users..."
            className="w-full px-4 py-2 border rounded-lg"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <Search className="absolute right-3 top-2.5 text-gray-400" size={20} />
        </div>

        <Select
          placeholder="Sort by..."
          options={sortOptions}
          onChange={handleSort}
          value={sortOptions.find(
            option => option.value === `${sortConfig.key}-${sortConfig.direction}`
          )}
          className="w-full"
        />
        
        <Select
          placeholder="Select Company"
          options={Object.entries(companies).map(([id, name]) => ({
            value: id,
            label: name
          }))}
          value={filters.company ? { 
            value: filters.company, 
            label: companies[filters.company]
          } : null}
          onChange={(option) => setFilters({ ...filters, company: option ? option.value : '' })}
          isClearable
          className="w-full"
        />

        <Select
          placeholder="Select Role"
          options={roleOptions}
          value={roleOptions.find(option => option.value === filters.role)}
          onChange={(option) => setFilters({ ...filters, role: option ? option.value : '' })}
          isClearable
          className="w-full"
        />
      </div>

      {error && (
        <div className="mb-4 p-3 bg-red-100 text-red-700 rounded flex items-center">
          <AlertCircle className="mr-2" size={20} />
          {error}
        </div>
      )}

      {isLoading ? (
        <div className="flex justify-center items-center h-64">
          <div className="animate-spin rounded-full h-32 w-32 border-b-2 border-gray-900"></div>
        </div>
      ) : (
        <>
          <div className="overflow-x-auto bg-white rounded-lg shadow overflow-y-auto relative">
            <table className="border-collapse table-auto w-full whitespace-no-wrap bg-white table-striped relative">
              <thead>
                <tr className="text-left">
                  <th 
                    className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-3 text-gray-600 font-bold tracking-wider uppercase text-xs cursor-pointer"
                    onClick={() => handleColumnSort('fullName')}
                  >
                    <div className="flex items-center">
                      Full Name {getSortIcon('fullName')}
                    </div>
                  </th>
                  <th 
                    className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-3 text-gray-600 font-bold tracking-wider uppercase text-xs cursor-pointer"
                    onClick={() => handleColumnSort('email')}
                  >
                    <div className="flex items-center">
                      Email {getSortIcon('email')}
                    </div>
                  </th>
                  <th 
                    className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-3 text-gray-600 font-bold tracking-wider uppercase text-xs cursor-pointer"
                    onClick={() => handleColumnSort('role')}
                  >
                    <div className="flex items-center">
                      Role {getSortIcon('role')}
                    </div>
                  </th>
                  <th 
                    className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-3 text-gray-600 font-bold tracking-wider uppercase text-xs cursor-pointer"
                    onClick={() => handleColumnSort('company')}
                  >
                    <div className="flex items-center">
                      Company {getSortIcon('company')}
                    </div>
                  </th>
                  <th 
                    className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-3 text-gray-600 font-bold tracking-wider uppercase text-xs cursor-pointer"
                    onClick={() => handleColumnSort('accessPrivileges')}
                  >
                    <div className="flex items-center">
                      Access {getSortIcon('accessPrivileges')}
                    </div>
                  </th>
                  <th 
                    className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-3 text-gray-600 font-bold tracking-wider uppercase text-xs cursor-pointer"
                    onClick={() => handleColumnSort('dateCreated')}
                  >
                    <div className="flex items-center">
                      Created {getSortIcon('dateCreated')}
                    </div>
                  </th>
                  <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-3 text-gray-600 font-bold tracking-wider uppercase text-xs">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody>
                {users.map((user) => (
                  <tr key={user.id}>
                    <td className="border-dashed border-t border-gray-200 px-6 py-4">{user.fullName}</td>
                    <td className="border-dashed border-t border-gray-200 px-6 py-4">{user.email}</td>
                    <td className="border-dashed border-t border-gray-200 px-6 py-4">{user.role}</td>
                    <td className="border-dashed border-t border-gray-200 px-6 py-4">
                      {companies[user.companyId] || companies[user.company] || 'N/A'}
                    </td>
                    <td className="border-dashed border-t border-gray-200 px-6 py-4">
                      <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium
                        ${user.accessPrivileges === 'Active' 
                          ? 'bg-green-100 text-green-800'
                          : 'bg-red-100 text-red-800'}`}>
                        {user.accessPrivileges}
                      </span>
                    </td>
                    <td className="border-dashed border-t border-gray-200 px-6 py-4">
                      {new Date(user.dateCreated).toLocaleDateString()}
                    </td>
                    <td className="border-dashed border-t border-gray-200 px-6 py-4">
                      <div className="flex items-center">
                      <Link 
                          to={`/edit-user/${user.id}`}
                          className="text-indigo-600 hover:text-indigo-900"
                          title="Edit user"
                        >
                          <Edit size={18} />
                        </Link>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          
          {/* Pagination Controls */}
          <div className="flex justify-between items-center mt-6">
            <span className="text-sm text-gray-700">
              Page {currentPage} of {Math.ceil(totalItems / pageSize)} 
              {' '}({totalItems} total users)
            </span>
            <div className="flex space-x-2">
              <button
                onClick={handlePreviousPage}
                disabled={currentPage === 1}
                className="px-3 py-2 rounded-lg border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 disabled:opacity-50 disabled:cursor-not-allowed flex items-center"
              >
                <ChevronLeft size={20} />
                <span className="ml-1">Previous</span>
              </button>
              <button
                onClick={handleNextPage}
                disabled={!lastKey}
                className="px-3 py-2 rounded-lg border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 disabled:opacity-50 disabled:cursor-not-allowed flex items-center"
              >
                <span className="mr-1">Next</span>
                <ChevronRight size={20} />
              </button>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default UserList;