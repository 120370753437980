import React, { useState, useEffect } from "react";
import { ref, get } from "firebase/database";
import { db, auth } from "../firebase";
import { Plus, AlertCircle } from "lucide-react";
import { useNavigate } from "react-router-dom";
import { onAuthStateChanged } from "firebase/auth";

const UserRoles = {
  ADMIN: "admin",
  MERCHANDISER_MANAGER: "Merchandiser and Manager",
  MERCHANDISER: "Merchandiser",
  MANAGER: "Manager",
};

// Updated API URL to use Cloud Function
const API_BASE_URL = "https://us-central1-smerch-39c4d.cloudfunctions.net/users";

const AddUser = () => {
  const [formData, setFormData] = useState({
    email: "",
    password: "",
    confirmPassword: "",
    role: "",
    companyId: "",
    fullName: "",
    accessPrivileges: "Active",
  });

  const [companies, setCompanies] = useState([]);
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isInitializing, setIsInitializing] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCompanies = async () => {
      try {
        const companiesRef = ref(db, "companies");
        const snapshot = await get(companiesRef);

        if (snapshot.exists()) {
          const companiesData = Object.entries(snapshot.val())
              .map(([id, data]) => ({
                id,
                ...data,
              }))
              .sort((a, b) => a.companyName.localeCompare(b.companyName));

          setCompanies(companiesData);
        } else {
          setError("No companies found. Please add a company first.");
        }
      } catch (error) {
        console.error("Error fetching companies:", error);
        setError("Failed to fetch companies. Please try again later.");
      }
    };

    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        fetchCompanies();
      } else {
        navigate("/login");
      }
      setIsInitializing(false);
    });

    return () => unsubscribe();
  }, [navigate]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
    setError("");
  };

  const validateForm = () => {
    const requiredFields = !formData.email || !formData.password ||
      !formData.confirmPassword || !formData.role ||
      !formData.companyId || !formData.fullName;

    if (requiredFields) {
      setError("All fields are required");
      return false;
    }

    if (formData.password !== formData.confirmPassword) {
      setError("Passwords do not match");
      return false;
    }

    if (formData.password.length < 6) {
      setError("Password must be at least 6 characters long");
      return false;
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(formData.email)) {
      setError("Invalid email format");
      return false;
    }

    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    setIsLoading(true);
    setError("");

    try {
      const user = auth.currentUser;
      if (!user) {
        throw new Error("You must be logged in to perform this action.");
      }

      const idToken = await user.getIdToken(true);

      const response = await fetch(`${API_BASE_URL}/create`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${idToken}`,
        },
        body: JSON.stringify({
          email: formData.email,
          password: formData.password,
          role: formData.role,
          companyId: formData.companyId,
          fullName: formData.fullName,
          accessPrivileges: formData.accessPrivileges,
          dateCreated: new Date().toISOString(),
          dateModified: new Date().toISOString(),
          createdBy: user.uid,
        }),
      });

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.message || "Failed to create user");
      }

      console.log("User created successfully:", data);
      navigate("/users");
    } catch (error) {
      console.error("Error creating user:", error);
      setError(error.message || "Failed to create user");
    } finally {
      setIsLoading(false);
    }
  };

  if (isInitializing) {
    return (
      <div className="flex justify-center items-center py-8">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 
          border-indigo-600">
        </div>
      </div>
    );
  }

  return (
    <div className="max-w-md mx-auto mt-10 p-6 bg-white rounded-lg shadow-xl text-left">
      <h2 className="text-2xl font-bold mb-6 text-gray-800">Add User</h2>

      {error && (
        <div className="mb-4 p-3 bg-red-100 text-red-700 rounded 
          flex items-center">
          <AlertCircle className="mr-2" size={20} />
          {error}
        </div>
      )}

      <form onSubmit={handleSubmit} className="space-y-4">
        <div>
          <label className="block text-sm font-medium text-gray-700">
            Full Name:
          </label>
          <input
            type="text"
            name="fullName"
            value={formData.fullName}
            onChange={handleInputChange}
            required
            className="mt-1 block w-full rounded-md border-gray-300 
              shadow-sm focus:border-indigo-300 focus:ring 
              focus:ring-indigo-200 focus:ring-opacity-50"
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">
            Email:
          </label>
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleInputChange}
            required
            className="mt-1 block w-full rounded-md border-gray-300 
              shadow-sm focus:border-indigo-300 focus:ring 
              focus:ring-indigo-200 focus:ring-opacity-50"
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">
            Password:
          </label>
          <input
            type="password"
            name="password"
            value={formData.password}
            onChange={handleInputChange}
            required
            minLength={6}
            className="mt-1 block w-full rounded-md border-gray-300 
              shadow-sm focus:border-indigo-300 focus:ring 
              focus:ring-indigo-200 focus:ring-opacity-50"
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">
            Confirm Password:
          </label>
          <input
            type="password"
            name="confirmPassword"
            value={formData.confirmPassword}
            onChange={handleInputChange}
            required
            minLength={6}
            className="mt-1 block w-full rounded-md border-gray-300 
              shadow-sm focus:border-indigo-300 focus:ring 
              focus:ring-indigo-200 focus:ring-opacity-50"
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">
            Role:
          </label>
          <select
            name="role"
            value={formData.role}
            onChange={handleInputChange}
            required
            className="mt-1 block w-full rounded-md border-gray-300 
              shadow-sm focus:border-indigo-300 focus:ring 
              focus:ring-indigo-200 focus:ring-opacity-50"
          >
            <option value="">Select Role</option>
            {Object.entries(UserRoles).map(([key, value]) => (
              <option key={key} value={value}>
                {value}
              </option>
            ))}
          </select>
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">
            Company:
          </label>
          <select
            name="companyId"
            value={formData.companyId}
            onChange={handleInputChange}
            required
            className="mt-1 block w-full rounded-md border-gray-300 
              shadow-sm focus:border-indigo-300 focus:ring 
              focus:ring-indigo-200 focus:ring-opacity-50"
          >
            <option value="">Select Company</option>
            {companies.map((company) => (
              <option key={company.id} value={company.id}>
                {company.companyName}
              </option>
            ))}
          </select>
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">
            Access Status:
          </label>
          <select
            name="accessPrivileges"
            value={formData.accessPrivileges}
            onChange={handleInputChange}
            required
            className="mt-1 block w-full rounded-md border-gray-300 
              shadow-sm focus:border-indigo-300 focus:ring 
              focus:ring-indigo-200 focus:ring-opacity-50"
          >
            <option value="Active">Active</option>
            <option value="Inactive">Inactive</option>
            <option value="Deactivated">Deactivated</option>
          </select>
        </div>

        <button
          type="submit"
          disabled={isLoading}
          className="w-full flex justify-center py-2 px-4 border 
            border-transparent rounded-md shadow-sm text-sm font-medium 
            text-white bg-indigo-600 hover:bg-indigo-700 
            focus:outline-none focus:ring-2 focus:ring-offset-2 
            focus:ring-indigo-500"
        >
          {isLoading ? (
            <div className="flex items-center">
              <div className="animate-spin rounded-full h-5 w-5 
                border-b-2 border-white mr-2">
              </div>
              Creating...
            </div>
          ) : (
            <>
              <Plus className="mr-2" size={20} />
              Add User
            </>
          )}
        </button>
      </form>
    </div>
  );
};

export default AddUser;
