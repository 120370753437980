import { initializeApp } from 'firebase/app';
import { getDatabase } from "firebase/database";
import { getAuth, signInWithEmailAndPassword, createUserWithEmailAndPassword, signOut } from "firebase/auth";
import { getStorage } from 'firebase/storage';
import { getFunctions } from 'firebase/functions';

// import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyD1M0EWowVTyWb8sqdnf4tk5S3Nfwug0F4",
  authDomain: "heineken-smerch.firebaseapp.com",
  databaseURL: "https://heineken-smerch-default-rtdb.firebaseio.com",
  projectId: "heineken-smerch",
  storageBucket: "heineken-smerch.firebasestorage.app",
  messagingSenderId: "907719702799",
  appId: "1:907719702799:web:0aa1bcab16c9374261e67a",
  measurementId: "G-NMFVED0ZES"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getDatabase(app);
const auth = getAuth(app);
const storage = getStorage(app);
const functions = getFunctions(app);

// const analytics = getAnalytics(app);
export { 
  db, 
  auth, 
  storage, 
  functions,
  signInWithEmailAndPassword, 
  createUserWithEmailAndPassword, 
  signOut 
};