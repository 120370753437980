import React, { useState, useEffect } from 'react';
import { ref, push, set, get } from 'firebase/database';
import { db, auth } from '../firebase';
import { Plus, AlertCircle } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { useLoadScript, StandaloneSearchBox } from '@react-google-maps/api';

const libraries = ["places"];

const AddStore = () => {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries
  });

  const [searchBox, setSearchBox] = useState(null);
  const [store, setStore] = useState({
    company: '',
    storeName: '',
    parish: '',
    searchLocation: '',
    latitude: null,
    longitude: null,
    channel: ''
  });
  const [companies, setCompanies] = useState([]);
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const parishes = [
    'All Parishes',
    'Kingston',
    'St. Andrew',
    'St. Catherine',
    'Clarendon',
    'Manchester',
    'St. Elizabeth',
    'Westmoreland',
    'Hanover',
    'St. James',
    'Trelawny',
    'St. Ann',
    'St. Mary',
    'Portland',
    'St. Thomas'
  ];

  useEffect(() => {
    const fetchCompanies = async () => {
      try {
        const companiesRef = ref(db, 'companies');
        const snapshot = await get(companiesRef);
        if (snapshot.exists()) {
          const companiesData = Object.entries(snapshot.val()).map(([id, data]) => ({
            id,
            ...data
          }));
          setCompanies(companiesData);
        }
      } catch (err) {
        console.error('Error fetching companies:', err);
        setError('Failed to fetch companies. Please try again.');
      }
    };

    fetchCompanies();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setStore({ ...store, [name]: value });
  };

  const handlePlacesChanged = () => {
    if (searchBox) {
      const places = searchBox.getPlaces();
      if (places && places.length > 0) {
        const place = places[0];
        setStore({
          ...store,
          searchLocation: place.formatted_address,
          latitude: place.geometry.location.lat(),
          longitude: place.geometry.location.lng()
        });
      }
    }
  };

  const onSearchBoxLoad = (ref) => {
    setSearchBox(ref);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setIsLoading(true);
    
    if (!store.latitude || !store.longitude) {
      setError('Please select a valid location from the suggestions');
      setIsLoading(false);
      return;
    }

    try {
      if (!auth.currentUser) {
        throw new Error('You must be logged in to add a store.');
      }
      const newStoreRef = push(ref(db, 'stores'));
      await set(newStoreRef, {
        ...store,
        dateCreated: new Date().toISOString(),
        dateModified: new Date().toISOString(),
        createdBy: auth.currentUser.uid,
      });
      console.log('Store added with ID: ', newStoreRef.key);
      navigate('/stores');
    } catch (error) {
      console.error('Error adding store: ', error);
      setError(error.message || 'Failed to add store. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  if (loadError) {
    return <div>Error loading maps</div>;
  }

  return (
    <div className="max-w-md mx-auto mt-10 p-6 bg-white rounded-lg shadow-xl text-left">
      <h2 className="text-2xl font-bold mb-6 text-gray-800">Add Store</h2>
      {error && (
        <div className="mb-4 p-3 bg-red-100 text-red-700 rounded flex items-center">
          <AlertCircle className="mr-2" size={20} />
          {error}
        </div>
      )}
      <form onSubmit={handleSubmit} className="space-y-4">
        <div>
          <label htmlFor="company" className="block text-sm font-medium text-gray-700">Company:</label>
          <select
            id="company"
            name="company"
            value={store.company}
            onChange={handleInputChange}
            required
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
          >
            <option value="">Select a company</option>
            {companies.map((company) => (
              <option key={company.id} value={company.id}>{company.companyName}</option>
            ))}
          </select>
        </div>
        <div>
          <label htmlFor="storeName" className="block text-sm font-medium text-gray-700">Store Name:</label>
          <input
            type="text"
            id="storeName"
            name="storeName"
            value={store.storeName}
            onChange={handleInputChange}
            required
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
          />
        </div>
        <div>
          <label htmlFor="parish" className="block text-sm font-medium text-gray-700">Parish:</label>
          <select
            id="parish"
            name="parish"
            value={store.parish}
            onChange={handleInputChange}
            required
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
          >
            <option value="">Select a parish</option>
            {parishes.map((parish) => (
              <option key={parish} value={parish}>{parish}</option>
            ))}
          </select>
        </div>
        <div>
          <label htmlFor="searchLocation" className="block text-sm font-medium text-gray-700">Search Location:</label>
          {isLoaded && (
            <StandaloneSearchBox
              onLoad={onSearchBoxLoad}
              onPlacesChanged={handlePlacesChanged}
            >
              <input
                type="text"
                id="searchLocation"
                name="searchLocation"
                value={store.searchLocation}
                onChange={(e) => setStore({ ...store, searchLocation: e.target.value })}
                placeholder="Enter location"
                required
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
              />
            </StandaloneSearchBox>
          )}
          {!isLoaded && (
            <input
              type="text"
              placeholder="Loading..."
              disabled
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
            />
          )}
        </div>
        <div>
          <label htmlFor="channel" className="block text-sm font-medium text-gray-700">Channel:</label>
          <select
            id="channel"
            name="channel"
            value={store.channel}
            onChange={handleInputChange}
            required
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
          >
            <option value="">Select a channel</option>
            <option value="All">All</option>
            <option value="Bar">Bar</option>
            <option value="Supermarket">Supermarket</option>
            <option value="Pharmacy">Pharmacy</option>
            <option value="Wholesale">Wholesale</option>
            <option value="GasSation">Gas Station</option>
          </select>
        </div>
        <button 
          type="submit" 
          className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          disabled={isLoading}
        >
          {isLoading ? 'Adding...' : (
            <>
              <Plus className="mr-2" size={20} />
              Add Store
            </>
          )}
        </button>
      </form>
    </div>
  );
};

export default AddStore;