import React, { useState, useEffect, useRef } from 'react';
import { ref, query, orderByChild, get, update, push } from 'firebase/database';
import { db, auth } from '../firebase';
import { Link, useNavigate } from 'react-router-dom';
import { Edit, Trash2, ChevronLeft, ChevronRight, Plus, AlertCircle, Upload, Search, Download } from 'lucide-react';
import Papa from 'papaparse';
import Select from 'react-select';

const CategoryList = () => {
  // State Management
  const [categories, setCategories] = useState([]);
  const [companies, setCompanies] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const fileInputRef = useRef(null);
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectAll, setSelectAll] = useState(false);

  // Filter States
  const [searchTerm, setSearchTerm] = useState('');
  const [filters, setFilters] = useState({
    company: ''
  });

  // Constants
  const PAGE_SIZE = 50;
  const navigate = useNavigate();

  // Sample CSV data with user-friendly column names
  const sampleCsvData = [
    {
      categoryName: "Example Category 1",
      companyName: "Example Company Name"
    },
    {
      categoryName: "Example Category 2",
      companyName: "Example Company Name"
    }
  ];

  useEffect(() => {
    fetchInitialData();
  }, []);

  const fetchInitialData = async () => {
    setIsLoading(true);
    try {
      const [companiesSnapshot, categoriesSnapshot] = await Promise.all([
        get(ref(db, 'companies')),
        get(query(ref(db, 'categories'), orderByChild('dateCreated')))
      ]);

      setCompanies(companiesSnapshot.val() || {});

      if (categoriesSnapshot.exists()) {
        const categoriesData = [];
        categoriesSnapshot.forEach((childSnapshot) => {
          const categoryData = childSnapshot.val();
          if (!categoryData.isDeleted) {
            categoriesData.push({ id: childSnapshot.key, ...categoryData });
          }
        });
        // Sort by date created in descending order
        categoriesData.sort((a, b) => new Date(b.dateCreated) - new Date(a.dateCreated));
        setCategories(categoriesData);
      } else {
        setCategories([]);
      }
    } catch (err) {
      console.error('Error fetching data:', err);
      setError('Failed to load data. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleSelectAll = (e) => {
    setSelectAll(e.target.checked);
    if (e.target.checked) {
      setSelectedItems(getCurrentPageCategories().map(item => item.id));
    } else {
      setSelectedItems([]);
    }
  };

  const handleSelectItem = (categoryId) => {
    setSelectedItems(prevSelected => {
      if (prevSelected.includes(categoryId)) {
        return prevSelected.filter(id => id !== categoryId);
      } else {
        return [...prevSelected, categoryId];
      }
    });
  };

  const handleBulkDelete = async () => {
    if (selectedItems.length === 0) {
      alert('Please select items to delete');
      return;
    }

    if (window.confirm(`Are you sure you want to delete ${selectedItems.length} categories?`)) {
      setIsDeleting(true);
      try {
        const updates = {};
        selectedItems.forEach(categoryId => {
          updates[`categories/${categoryId}`] = {
            ...categories.find(cat => cat.id === categoryId),
            isDeleted: true,
            dateModified: new Date().toISOString()
          };
        });

        await update(ref(db), updates);
        await fetchInitialData();
        setSelectedItems([]);
        setSelectAll(false);
        alert('Categories deleted successfully');
      } catch (err) {
        console.error('Error deleting categories:', err);
        setError('Failed to delete categories. Please try again.');
      } finally {
        setIsDeleting(false);
      }
    }
  };

  const exportData = () => {
    const dataToExport = categories.map(category => ({
      categoryName: category.categoryName,
      companyName: companies[category.companyId]?.companyName || 'Unknown',
      createdBy: category.createdBy || 'Unknown',
      dateCreated: new Date(category.dateCreated).toLocaleString(),
      dateModified: new Date(category.dateModified).toLocaleString()
    }));

    const csv = Papa.unparse(dataToExport);
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    const url = URL.createObjectURL(blob);
    link.setAttribute('href', url);
    link.setAttribute('download', `categories_export_${new Date().toISOString()}.csv`);
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const findIdByName = (collection, field, name) => {
    const entry = Object.entries(collection).find(([_, item]) => 
      item[field]?.toLowerCase() === name?.toLowerCase()
    );
    return entry ? entry[0] : null;
  };

  const getFilteredCategories = () => {
    return categories.filter(category => {
      const matchesSearch = !searchTerm || 
        category.categoryName.toLowerCase().includes(searchTerm.toLowerCase());
      
      const matchesCompany = !filters.company || category.companyId === filters.company;

      return matchesSearch && matchesCompany;
    });
  };

  const getCurrentPageCategories = () => {
    const filteredCategories = getFilteredCategories();
    const startIndex = (currentPage - 1) * PAGE_SIZE;
    return filteredCategories.slice(startIndex, startIndex + PAGE_SIZE);
  };

  const handleNextPage = () => {
    const totalPages = Math.ceil(getFilteredCategories().length / PAGE_SIZE);
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
      setSelectAll(false);
      setSelectedItems([]);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
      setSelectAll(false);
      setSelectedItems([]);
    }
  };

  const handleDeleteCategory = async (categoryId) => {
    if (window.confirm('Are you sure you want to delete this category?')) {
      setIsDeleting(true);
      try {
        const categoryRef = ref(db, `categories/${categoryId}`);
        await update(categoryRef, { 
          isDeleted: true,
          dateModified: new Date().toISOString()
        });
        await fetchInitialData();
        alert('Category deleted successfully');
      } catch (err) {
        console.error('Error deleting category:', err);
        setError('Failed to delete category. Please try again.');
      } finally {
        setIsDeleting(false);
      }
    }
  };

  const downloadSampleCsv = () => {
    const headerRow = {
      categoryName: "Category Name (Required)",
      companyName: "Company Name (Required - Must match existing company)"
    };

    const csvData = [headerRow, ...sampleCsvData];
    const csv = Papa.unparse(csvData);
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', 'category_template.csv');
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      setIsUploading(true);
      setError('');
      Papa.parse(file, {
        complete: handleFileParseComplete,
        header: true,
        error: (error) => {
          setError(`Error parsing file: ${error.message}`);
          setIsUploading(false);
        }
      });
    }
  };

  const handleFileParseComplete = async (results) => {
    try {
      const { data } = results;
      const categoriesRef = ref(db, 'categories');
      const updates = {};
      const errors = [];
      let rowNum = 0;

      const currentUser = auth.currentUser;

      for (const row of data) {
        rowNum++;
        if (!row.categoryName || !row.companyName) {
          errors.push(`Row ${rowNum}: Missing required fields`);
          continue;
        }

        const companyId = findIdByName(companies, 'companyName', row.companyName);

        if (!companyId) {
          errors.push(`Row ${rowNum}: Company "${row.companyName}" not found`);
          continue;
        }

        const newCategoryRef = push(categoriesRef);
        updates[newCategoryRef.key] = {
          categoryName: row.categoryName.trim(),
          companyId,
          createdBy: currentUser?.uid || 'system',
          dateCreated: new Date().toISOString(),
          dateModified: new Date().toISOString()
        };
      }

      if (errors.length > 0) {
        setError(`Upload errors:\n${errors.join('\n')}`);
        return;
      }

      if (Object.keys(updates).length === 0) {
        setError('No valid categories found in the CSV file');
        return;
      }

      await update(categoriesRef, updates);
      alert(`Successfully uploaded ${Object.keys(updates).length} categories`);
      fetchInitialData();
    } catch (error) {
      console.error('Error uploading categories:', error);
      setError('Failed to upload categories. Please try again.');
    } finally {
      setIsUploading(false);
    }
  };

  return (
    <div className="container mx-auto px-4 sm:px-6 lg:px-8 py-8 text-left">
      {/* Header Section */}
      <div className="flex flex-col sm:flex-row justify-between items-center mb-6">
        <h2 className="text-2xl font-semibold leading-tight mb-2 sm:mb-0">Category List</h2>
        <div className="flex space-x-2">
          <button
            onClick={downloadSampleCsv}
            className="bg-purple-500 hover:bg-purple-700 text-white font-bold py-2 px-4 rounded flex items-center"
          >
            <Download className="mr-2" size={20} />
            Download Template
          </button>
          <button
            onClick={exportData}
            className="bg-indigo-500 hover:bg-indigo-700 text-white font-bold py-2 px-4 rounded flex items-center"
          >
            <Download className="mr-2" size={20} />
            Export
          </button>
          <button
            onClick={() => fileInputRef.current.click()}
            className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded flex items-center"
            disabled={isUploading}
          >
            <Upload className="mr-2" size={20} />
            {isUploading ? 'Uploading...' : 'Upload'}
          </button>
          <input
            type="file"
            ref={fileInputRef}
            onChange={handleFileUpload}
            accept=".csv"
            className="hidden"
          />
          <button
            onClick={() => navigate('/add-category')}
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded flex items-center"
          >
            <Plus className="mr-2" size={20} />
            Add
          </button>
          {selectedItems.length > 0 && (
            <button
              onClick={handleBulkDelete}
              className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded flex items-center"
              disabled={isDeleting}
            >
              <Trash2 className="mr-2" size={20} />
              Delete ({selectedItems.length})
            </button>
          )}
        </div>
      </div>

      {/* Search and Filters Section */}
      <div className="mb-6 grid grid-cols-1 md:grid-cols-2 gap-4">
        <div className="relative">
          <input
            type="text"
            placeholder="Search categories..."
            className="w-full px-4 py-2 border rounded-lg"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <Search className="absolute right-3 top-2.5 text-gray-400" size={20} />
        </div>
        
        <Select
          placeholder="Select Company"
          options={Object.entries(companies).map(([id, company]) => ({
            value: id,
            label: company.companyName
          }))}
          value={filters.company ? { 
            value: filters.company, 
            label: companies[filters.company]?.companyName 
          } : null}
          onChange={(option) => setFilters({ ...filters, company: option ? option.value : '' })}
          isClearable
          className="w-full"
        />
      </div>
      
      {error && (
        <div className="mb-4 p-3 bg-red-100 text-red-700 rounded flex items-center">
          <AlertCircle className="mr-2" size={20} />
          <pre className="whitespace-pre-wrap">{error}</pre>
        </div>
      )}
      
      {isLoading ? (
        <div className="flex justify-center items-center h-64">
          <div className="animate-spin rounded-full h-32 w-32 border-b-2 border-gray-900"></div>
        </div>
      ) : (
        <>
          <div className="overflow-x-auto bg-white rounded-lg shadow overflow-y-auto relative">
            <table className="border-collapse table-auto w-full whitespace-no-wrap bg-white table-striped relative">
              <thead>
                <tr className="text-left">
                  <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-3">
                    <input
                      type="checkbox"
                      checked={selectAll}
                      onChange={handleSelectAll}
                      className="form-checkbox h-4 w-4 text-blue-600"
                    />
                  </th>
                  <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-3 text-gray-600 font-bold tracking-wider uppercase text-xs">Category Name</th>
                  <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-3 text-gray-600 font-bold tracking-wider uppercase text-xs">Company</th>
                  <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-3 text-gray-600 font-bold tracking-wider uppercase text-xs">Date Modified</th>
                  <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-3 text-gray-600 font-bold tracking-wider uppercase text-xs">Date Created</th>
                  <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-3 text-gray-600 font-bold tracking-wider uppercase text-xs">Actions</th>
                </tr>
              </thead>
              <tbody>
                {getCurrentPageCategories().map((category) => (
                  <tr key={category.id}>
                    <td className="border-dashed border-t border-gray-200 px-6 py-4">
                      <input
                        type="checkbox"
                        checked={selectedItems.includes(category.id)}
                        onChange={() => handleSelectItem(category.id)}
                        className="form-checkbox h-4 w-4 text-blue-600"
                      />
                    </td>
                    <td className="border-dashed border-t border-gray-200 px-6 py-4">{category.categoryName}</td>
                    <td className="border-dashed border-t border-gray-200 px-6 py-4">{companies[category.companyId]?.companyName || 'Unknown'}</td>
                    <td className="border-dashed border-t border-gray-200 px-6 py-4">{new Date(category.dateModified).toLocaleString()}</td>
                    <td className="border-dashed border-t border-gray-200 px-6 py-4">{new Date(category.dateCreated).toLocaleString()}</td>
                    <td className="border-dashed border-t border-gray-200 px-6 py-4">
                      <div className="flex space-x-3">
                        <Link 
                          to={`/edit-category/${category.id}`} 
                          className="text-indigo-600 hover:text-indigo-900"
                        >
                          <Edit size={18} />
                        </Link>
                        <button 
                          onClick={() => handleDeleteCategory(category.id)} 
                          className="text-red-600 hover:text-red-900"
                          disabled={isDeleting}
                        >
                          <Trash2 size={18} />
                        </button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          
          {/* Pagination Controls */}
          <div className="flex justify-between items-center mt-6">
            <span className="text-sm text-gray-700">
              Page {currentPage} of {Math.ceil(getFilteredCategories().length / PAGE_SIZE)} 
              {' '}({getFilteredCategories().length} total categories)
              {selectedItems.length > 0 && ` • ${selectedItems.length} selected`}
            </span>
            <div className="flex space-x-2">
              <button
                onClick={handlePreviousPage}
                disabled={currentPage === 1}
                className="px-3 py-2 rounded-lg border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 disabled:opacity-50 disabled:cursor-not-allowed flex items-center"
              >
                <ChevronLeft size={20} />
                <span className="ml-1">Previous</span>
              </button>
              <button
                onClick={handleNextPage}
                disabled={currentPage >= Math.ceil(getFilteredCategories().length / PAGE_SIZE)}
                className="px-3 py-2 rounded-lg border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 disabled:opacity-50 disabled:cursor-not-allowed flex items-center"
              >
                <span className="mr-1">Next</span>
                <ChevronRight size={20} />
              </button>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default CategoryList;