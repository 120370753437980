import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { signOut } from 'firebase/auth';
import { auth } from '../firebase';
import { 
  Home, 
  Building, 
  Package, 
  List, 
  Store, 
  Users, 
  // Activity,
  LogOut,
  X,
  User
} from 'lucide-react';

const Sidebar = ({ isOpen, toggleSidebar, userRole, userName }) => {
  const location = useLocation();

  const handleLogout = async () => {
    try {
      await signOut(auth);
    } catch (error) {
      console.error('Error signing out: ', error);
    }
  };

  const navItems = [
    { path: '/dashboard', name: 'Dashboard', icon: Home },
    { path: '/companies', name: 'Company Info', icon: Building },
    { path: '/stores', name: 'Stores', icon: Store },
    { path: '/products', name: 'Products', icon: Package },
    { path: '/categories', name: 'Categories', icon: List },
    { path: '/users', name: 'Company Users', icon: Users },
    // { path: '/activity', name: 'Activity', icon: Activity },
  ];

  return (
    <>
      <div
        className={`${
          isOpen ? 'translate-x-0' : '-translate-x-full'
        } fixed inset-y-0 left-0 z-30 w-64 bg-gray-800 transition duration-300 ease-in-out transform lg:translate-x-0 lg:static lg:inset-0`}
      >
        {/* Mobile Header */}
        <div className="flex items-center justify-between h-16 px-6 bg-gray-900 lg:hidden">
          <h2 className="text-2xl font-bold text-white">Menu</h2>
          <button onClick={toggleSidebar} className="text-gray-500 focus:outline-none focus:text-gray-600">
            <X className="w-6 h-6" />
          </button>
        </div>

        {/* User Profile Section */}
        <div className="px-6 py-4 bg-gray-900">
          <div className="flex items-center space-x-3">
            <div className="bg-gray-700 p-2 rounded-full">
              <User className="w-6 h-6 text-gray-300" />
            </div>
            <div>
              <p className="text-sm font-medium text-white">{userName}</p>
              <p className="text-xs text-gray-400">{userRole}</p>
            </div>
          </div>
        </div>

        {/* Navigation */}
        <div className="px-4 py-6">
          <h2 className="text-xl font-bold text-white mb-6 hidden lg:block">Smerch Admin</h2>
          <nav>
            <ul className="space-y-2">
              {navItems.map(item => (
                <li key={item.path}>
                  <Link
                    to={item.path}
                    className={`flex items-center px-4 py-2 text-sm ${
                      location.pathname === item.path
                        ? 'bg-gray-700 text-white'
                        : 'text-gray-300 hover:bg-gray-700 hover:text-white'
                    } rounded-md transition-colors duration-200`}
                    onClick={() => window.innerWidth < 1024 && toggleSidebar()}
                  >
                    <item.icon className="w-5 h-5 mr-3" />
                    <span>{item.name}</span>
                  </Link>
                </li>
              ))}
            </ul>
          </nav>
        </div>

        {/* Logout Button */}
        <div className="absolute bottom-0 w-full border-t border-gray-700">
          <div className="px-4 py-4">
            <button
              onClick={handleLogout}
              className="flex items-center w-full px-4 py-2 text-sm text-gray-300 hover:bg-gray-700 hover:text-white rounded-md transition-colors duration-200"
            >
              <LogOut className="w-5 h-5 mr-3" />
              <span>Logout</span>
            </button>
          </div>
        </div>
      </div>

      {/* Overlay for mobile */}
      {isOpen && (
        <div
          className="fixed inset-0 z-20 bg-black opacity-50 transition-opacity lg:hidden"
          onClick={toggleSidebar}
        ></div>
      )}
    </>
  );
};

export default Sidebar;
